import React, { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { Outlet, Navigate, useNavigate } from "react-router-dom";

const ProtectedRoute = () => {
  const navigate = useNavigate();
  const [cookies, removeCookie] = useCookies([]);

  useEffect(() => {
    const verifyCookie = async () => {
      if (!cookies.token) {
        navigate("/login");
      }
    };
    verifyCookie();
  }, [cookies, navigate, removeCookie]);

  // return <>{cookies.token ? <Outlet /> : <Navigate to="/signup" />}</>;
  return <>{cookies.token ? <Outlet /> : <Navigate to="/login" />}</>;
};

export default ProtectedRoute;
