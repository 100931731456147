import React, { useState, useEffect } from "react";
import axios from "axios";

const GetAllRecords = ({
  endpointToGetAllRecords,
  endpointToDeleteRecord,
  onDelete,
  children,
}) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadItems = async () => {
      try {
        const { data } = await axios.get(endpointToGetAllRecords);
        setData(data);
        setLoading(false);
        console.log(data);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };
    loadItems();
  }, [endpointToGetAllRecords, endpointToDeleteRecord, onDelete]);

  const handleDeleteInternal = async (itemId) => {
    try {
      await axios.delete(`${endpointToDeleteRecord}${itemId}`);
      // remove the deleted about from the data array
      onDelete(itemId);
      console.log("item deleted successfully");
    } catch (error) {
      console.error("failed to delete item", error);
    }
  };

  return <>{children({ data, loading, handleDeleteInternal })}</>;
};

export default GetAllRecords;
