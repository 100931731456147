import React from "react";

const NotFound = () => {
  return (
    <div className="container">
      <div className="row">
        <h1 className="text-center m-5">
          ERROR - 404 <br /> NotFound!
        </h1>
      </div>
    </div>
  );
};

export default NotFound;
