import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Manage = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const loadItems = async () => {
      const { data } = await axios.get(
        "/api/contact-us/footer/content/show-all"
      );
      setData(data);
      console.log(data);
    };
    loadItems();
  }, []);

  const handleDelete = async (itemId) => {
    try {
      await axios.delete(`/api/contact-us/footer/content/delete/${itemId}`);
      // remove the deleted about from the data array
      setData((prevData) => prevData.filter((item) => item._id !== itemId));
      console.log("item deleted successfully");
    } catch (error) {
      console.error("failed to delete item", error);
    }
  };

  return (
    <div className="container">
      <h3 className="text-center">Manage Footer Content For Contact Us Page</h3>
      <table className="table table-striped  table-hover">
        <thead>
          <tr>
            <th scope="col">Company Name</th>
            <th scope="col">Country Name</th>
            <th scope="col">Options</th>
            <th scope="col">Operations</th>
          </tr>
        </thead>
        <tbody>
          {data.map((d) => (
            <tr key={d._id}>
              <td className="col-2">{d.companyName}</td>
              <td className="col-2">{d.countryName}</td>
              <td className="col-2">
                <table class="table table-hover">
                  <thead>
                    <tr>
                      <th className="col">Option Name</th>
                      {/* <th className="col">Option Link</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {d.options.map((d, index) => (
                      <tr key={index}>
                        <td className="col">{d.optionName}</td>
                        {/* <td className="col">{d.optionLink}</td> */}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </td>
              <td className="col-3">
                <button
                  className="btn btn-danger"
                  onClick={() => handleDelete(d._id)}
                >
                  Delete
                </button>
                <span className="me-2 ms-2">|</span>
                <Link
                  to={`/admin/contact-us/footer/content/update/${d._id}`}
                  className="btn btn-warning"
                >
                  Edit
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Manage;
