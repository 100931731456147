import axios from "axios";
import Slider from "react-slick";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const ServicesSlider = () => {
  const [services, setServices] = useState([]);
  const [serviceHeader, setServiceHeader] = useState([]);
  const [homeServices, setHomeServices] = useState([]);

  useEffect(() => {
    const loadItems = async () => {
      const { data } = await axios.get("/api/service/show-all");
      setServices(data);
      console.log(data);
    };
    loadItems();
  }, []);

  useEffect(() => {
    const loadItems = async () => {
      const { data } = await axios.get("/api/home/service/show-all");
      setHomeServices(data);
      console.log(data);
    };
    loadItems();
  }, []);

  useEffect(() => {
    const loadItems = async () => {
      const { data } = await axios.get("/api/service/header/show-all");
      setServiceHeader(data);
      console.log(data);
    };
    loadItems();
  }, []);

  const sliderSettings = {
    infinite: true,
    speed: 500,
    arrows: false,
    slidesToShow: 6, // Adjust the number of slides shown as needed
    autoplay: true, // Auto-slide enabled
    autoplaySpeed: 3000, // Set the autoplay interval (in milliseconds)
    slidesToScroll: 1,
    // prevArrow: <CustomPrevArrow />,
    // nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2, // Adjust for smaller screens
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1, // Adjust for mobile screens
        },
      },
    ],
  };

  return (
    <section className="service-style-three sec-pad">
      <div className="container-fluid">
        <div className="sec-title text-center">
          <h3>{serviceHeader.length > 0 && serviceHeader[0].heading}</h3>
          <p>{serviceHeader.length > 0 && serviceHeader[0].description}</p>
        </div>
        {/* /.sec-title */}
        <Slider {...sliderSettings}>
          {services?.map((service) => (
            <div className="col-md-4 col-sm-6 col-xs-12">
              <div className="single-service-style-three">
                <div className="img-box">
                  <Link
                    className="text-decoration-none"
                    to={`/service-detail/${service._id}`}
                  >
                    <img
                      src={`/uploads/${service.image}`}
                      alt="Awesome Image"
                      className="img-fluid"
                      style={{
                        objectFit: "cover",
                        height: "208px",
                        width: "100%",
                      }}
                    />
                  </Link>
                  <div className="text-box text-center">
                    <Link
                      className="text-decoration-none"
                      to={`/service-detail/${service._id}`}
                    >
                      <div
                        className="inner hvr-bounce-to-bottom"
                        style={{ width: "100%" }}
                      >
                        <div className="icon-box">
                          <i className={`text-dark ${service.iconClass}`} />
                        </div>
                        {/* /.icon-box */}
                        <h3>{service.heading.substring(0, 10)}...</h3>
                        <p>{service.title.substring(0, 50)}...</p>
                        <Link
                          className="more text-decoration-none"
                          to={`/service-detail/${service._id}`}
                        >
                          Read More <i className="fa fa-arrow-right" />
                        </Link>
                      </div>
                    </Link>
                    {/* /.inner */}
                  </div>
                  {/* /.text-box */}
                </div>
                {/* /.img-box */}
              </div>
              {/* /.single-service-style-three */}
            </div>
          ))}
        </Slider>
      </div>
      {/* /.row */}
      {/* /.container */}
    </section>
  );
};

export default ServicesSlider;

const CustomPrevArrow = (props) => (
  <button
    {...props}
    className=" ms-4 slick-prev bg-primary rounded rounded-circle"
  >
    &lt;
  </button>
);

const CustomNextArrow = (props) => (
  <button
    {...props}
    className="me-4 slick-next bg-primary rounded rounded-circle"
  >
    <span>&gt;</span>
  </button>
);
