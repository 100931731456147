import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import TextArea from "../../../common/TextArea";
import Input from "../../../common/Input";
import Button from "../../../common/Button";

const UpdateAboutPage = () => {
  const [heading, setHeading] = useState("");
  const [url, setUrl] = useState("");
  const [serviceName, setServiceName] = useState("");
  const [iconClass, setIconClass] = useState("");
  const [image, setImage] = useState(null);
  const [fileSizeError, setFileSizeError] = useState(false);
  const [existingImage, setExistingImage] = useState("");
  const { id } = useParams();

  const created = "Created Successfully";
  const errorMessage = "something Bad Happend";
  const serviceNameError = "Service Name is missing";
  const headingError = "Heading is missing";
  const imageError = "image is missing";
  const imageSizeError = "Please choose file less than 5 MB";
  const iconCopied = "Copied";
  const iconClassError = "icon is missing";
  const urlError = "url is missing";

  const notifyCreate = (message) => toast.success(message);
  const notifyError = (message) => toast.error(message);

  useEffect(() => {
    const fetchAbout = async () => {
      try {
        const response = await axios.get(
          `/api/about/choose-service/get-single-item/${id}`
        );
        const itemData = response.data;
        console.log(itemData);
        setServiceName(itemData.serviceName);
        setHeading(itemData.heading);
        setIconClass(itemData.iconClass);
        setUrl(itemData.url);
        setExistingImage(`/uploads/${itemData.image}`);
      } catch (error) {
        console.log(error);
      }
    };
    fetchAbout();
  }, [id]);

  const handleUrlChange = (e) => {
    setUrl(e.target.value);
  };

  const handleServiceNameChange = (e) => {
    setServiceName(e.target.value);
  };

  const handleHeadingChange = (e) => {
    setHeading(e.target.value);
  };

  const handleIconChange = (e) => {
    setIconClass(e.target.value);
  };

  const handleImageChange = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      if (selectedFile.size > 5 * 1024 * 1024) {
        setFileSizeError(true);
        try {
          notifyError(imageSizeError);
        } catch (error) {
          console.log(error);
        }
      } else {
        setFileSizeError(false);
        setImage(selectedFile || existingImage);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("iconClass", iconClass);
    formData.append("url", url);
    formData.append("heading", heading);
    formData.append("image", image || existingImage);

    if (!fileSizeError) {
      switch (true) {
        case !heading:
          notifyError(headingError);
          break;

        case !serviceNameError:
          notifyError(serviceNameError);
          break;

        case !urlError:
          notifyError(urlError);
          break;

        case !iconClassError:
          notifyError(iconClassError);
          break;

        default:
          try {
            // send a POST request to the server to add the product
            const response = await axios.post(
              `/api/about/header/update/${id}`,
              formData
            );
            notifyCreate(created);
            // handle the response and perform any necessary actions
            console.log(response);
            console.log(response.data);

            // reset the form
          } catch (error) {
            notifyError(errorMessage);
            console.error(error);
          }
          break;
      }
    }
  };

  return (
    <div>
      <h3 className="text-center">Update Feature</h3>
      <form onSubmit={handleSubmit}>
        <Input
          value={serviceName}
          onChange={handleServiceNameChange}
          id="floatingInputServiceName"
          placeholder="ServiceName"
          label="ServiceName"
        />
        <Input
          value={url}
          onChange={handleUrlChange}
          id="floatingInputUrl"
          placeholder="Url"
          label="Url"
        />
        <Input
          value={iconClass}
          onChange={handleIconChange}
          id="floatingInputIcon"
          placeholder="Use theme Icon class names only i.e. industrio-icon-innovation"
          label="Icon Class Name"
        />
        <Input
          type="file"
          onChange={handleImageChange}
          id="floatingInputImage"
          placeholder="Image"
          label="Image - Please select file less than 5 MB - 490 x 421"
        />
        <div className="text-center">
          <Button name="Update" type="submit" className="btn btn-warning" />
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default UpdateAboutPage;
