import "./App.css";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./components/Website/Home";
import Dashboard from "./components/Admin/Dashboard";
// about banner
import AddAboutBanner from "./components/Admin/aboutUs/aboutBanner/Add";
import UpdateAboutBanner from "./components/Admin/aboutUs/aboutBanner/Update";
import ManageAboutBanner from "./components/Admin/aboutUs/aboutBanner/Manage";

// about header
import AddAboutHeader from "./components/Admin/aboutUs/aboutHeader/Add";
import UpdateAboutHeader from "./components/Admin/aboutUs/aboutHeader/Update";
import ManageAboutHeader from "./components/Admin/aboutUs/aboutHeader/Manage";

// about features
import AddAboutFeatures from "./components/Admin/aboutUs/aboutFeatures/Add";
import UpdateAboutFeatures from "./components/Admin/aboutUs/aboutFeatures/Update";
import ManageAboutFeatures from "./components/Admin/aboutUs/aboutFeatures/Manage";

// about history - header
import AddAboutHistoryHeader from "./components/Admin/aboutUs/aboutHistoryHeader/Add";
import UpdateAboutHistoryHeader from "./components/Admin/aboutUs/aboutHistoryHeader/Update";
import ManageAboutHistoryHeader from "./components/Admin/aboutUs/aboutHistoryHeader/Manage";

// about history - work
import AddAboutHistoryWork from "./components/Admin/aboutUs/aboutHistoryWork/Add";
import UpdateAboutHistoryWork from "./components/Admin/aboutUs/aboutHistoryWork/Update";
import ManageAboutHistoryWork from "./components/Admin/aboutUs/aboutHistoryWork/Manage";

// about choose - header
import AddAboutChooseHeader from "./components/Admin/aboutUs/aboutChooseHeader/Add";
import UpdateAboutChooseHeader from "./components/Admin/aboutUs/aboutChooseHeader/Update";
import ManageAboutChooseHeader from "./components/Admin/aboutUs/aboutChooseHeader/Manage";

// about choose - service
import AddAboutChooseService from "./components/Admin/aboutUs/aboutChooseService/Add";
import UpdateAboutChooseService from "./components/Admin/aboutUs/aboutChooseService/Update";
import ManageAboutChooseService from "./components/Admin/aboutUs/aboutChooseService/Manage";

// get in touch
import AddGetInTouch from "./components/Admin/GetTouch/Add";
import UpdateGetInTouch from "./components/Admin/GetTouch/Update";
import ManageGetInTouch from "./components/Admin/GetTouch/Manage";

// subscribe
import AddSubscribe from "./components/Admin/Subscribe/Add";
import UpdateSubscribe from "./components/Admin/Subscribe/Update";
import ManageSubscribe from "./components/Admin/Subscribe/Manage";

// home
import AddHomeSlider from "./components/Admin/home/homeSlider/Add";
import UpdateHomeSlider from "./components/Admin/home/homeSlider/Update";
import ManageHomeSlider from "./components/Admin/home/homeSlider/Manage";

import AddHomeHeader from "./components/Admin/home/homeHeader/Add";
import UpdateHomeHeader from "./components/Admin/home/homeHeader/Update";
import ManageHomeHeader from "./components/Admin/home/homeHeader/Manage";

import AddHomeHeaderPoint from "./components/Admin/home/homeHeaderPoint/Add";
import UpdateHomeHeaderPoint from "./components/Admin/home/homeHeaderPoint/Update";
import ManageHomeHeaderPoint from "./components/Admin/home/homeHeaderPoint/Manage";

import AddHomeService from "./components/Admin/home/HomeService/Add";
import UpdateHomeService from "./components/Admin/home/HomeService/Update";
import ManageHomeService from "./components/Admin/home/HomeService/Manage";

import AddHomeProjectsHeader from "./components/Admin/home/HomeProjectsHeader/Add";
import UpdateHomeProjectsHeader from "./components/Admin/home/HomeProjectsHeader/Update";
import ManageHomeProjectsHeader from "./components/Admin/home/HomeProjectsHeader/Manage";

import AddHomeBrand from "./components/Admin/home/HomeBrand/Add";
import UpdateHomeBrand from "./components/Admin/home/HomeBrand/Update";
import ManageHomeBrand from "./components/Admin/home/HomeBrand/Manage";

// project
import AddProjectBanner from "./components/Admin/project/ProjectBanner/Add";
import UpdateProjectBanner from "./components/Admin/project/ProjectBanner/Update";
import ManageProjectBanner from "./components/Admin/project/ProjectBanner/Manage";

import AddProject from "./components/Admin/project/Project/Add";
import UpdateProject from "./components/Admin/project/Project/Update";
import ManageProject from "./components/Admin/project/Project/Manage";

import AddProjectBottom from "./components/Admin/project/ProjectBottom/Add";
import UpdateProjectBottom from "./components/Admin/project/ProjectBottom/Update";
import ManageProjectBottom from "./components/Admin/project/ProjectBottom/Manage";

// contact
import AddContactBanner from "./components/Admin/ContactUs/ContactBanner/Add";
import UpdateContactBanner from "./components/Admin/ContactUs/ContactBanner/Update";
import ManageContactBanner from "./components/Admin/ContactUs/ContactBanner/Manage";

import AddContactHeader from "./components/Admin/ContactUs/ContactHeader/Add";
import UpdateContactHeader from "./components/Admin/ContactUs/ContactHeader/Update";
import ManageContactHeader from "./components/Admin/ContactUs/ContactHeader/Manage";

import AddContactForm from "./components/Admin/ContactUs/ContactForm/Add";
import UpdateContactForm from "./components/Admin/ContactUs/ContactForm/Update";
import ManageContactForm from "./components/Admin/ContactUs/ContactForm/Manage";

import AddFooterCompany from "./components/Admin/ContactUs/FooterCompany/Add";
import UpdateFooterCompany from "./components/Admin/ContactUs/FooterCompany/Update";
import ManageFooterCompany from "./components/Admin/ContactUs/FooterCompany/Manage";

import AddFooterCountry from "./components/Admin/ContactUs/FooterCountry/Add";
import UpdateFooterCountry from "./components/Admin/ContactUs/FooterCountry/Update";
import ManageFooterCountry from "./components/Admin/ContactUs/FooterCountry/Manage";

import AddContactFooterContent from "./components/Admin/ContactUs/FooterContent/Add";
import UpdateContactFooterContent from "./components/Admin/ContactUs/FooterContent/Update";
import ManageContactFooterContent from "./components/Admin/ContactUs/FooterContent/Manage";

// support
import AddSupport from "./components/Admin/Support/Add";
import UpdateSupport from "./components/Admin/Support/Update";
import ManageSupport from "./components/Admin/Support/Manage";

// logo
import AddLogo from "./components/Admin/Logo/Add";
import UpdateLogo from "./components/Admin/Logo/Update";
import ManageLogo from "./components/Admin/Logo/Manage";

// service
import AddServiceBanner from "./components/Admin/service/ServiceBanner/Add";
import UpdateServiceBanner from "./components/Admin/service/ServiceBanner/Update";
import ManageServiceBanner from "./components/Admin/service/ServiceBanner/Manage";

import AddServiceHeader from "./components/Admin/service/ServiceHeader/Add";
import UpdateServiceHeader from "./components/Admin/service/ServiceHeader/Update";
import ManageServiceHeader from "./components/Admin/service/ServiceHeader/Manage";

import AddService from "./components/Admin/service/Service/Add";
import UpdateService from "./components/Admin/service/Service/Update";
import ManageService from "./components/Admin/service/Service/Manage";

// choose us
import AddChooseUsHeader from "./components/Admin/chooseUs/ChooseUsHeader/Add";
import UpdateChooseUsHeader from "./components/Admin/chooseUs/ChooseUsHeader/Update";
import ManageChooseUsHeader from "./components/Admin/chooseUs/ChooseUsHeader/Manage";

import AddChooseUsProgress from "./components/Admin/chooseUs/ChooseUsProgress/Add";
import UpdateChooseUsProgress from "./components/Admin/chooseUs/ChooseUsProgress/Update";
import ManageChooseUsProgress from "./components/Admin/chooseUs/ChooseUsProgress/Manage";

import AddChooseUsFact from "./components/Admin/chooseUs/ChooseUsFact/Add";
import UpdateChooseUsFact from "./components/Admin/chooseUs/ChooseUsFact/Update";
import ManageChooseUsFact from "./components/Admin/chooseUs/ChooseUsFact/Manage";

import AddFooterContent from "./components/Admin/footer/FooterContent/Add";
import UpdateFooterContent from "./components/Admin/footer/FooterContent/Update";
import ManageFooterContent from "./components/Admin/footer/FooterContent/Manage";

import AddFooterCategory from "./components/Admin/footer/FooterCategory/Add";
import UpdateFooterCategory from "./components/Admin/footer/FooterCategory/Update";
import ManageFooterCategory from "./components/Admin/footer/FooterCategory/Manage";

import AddFooterLeftSide from "./components/Admin/footer/FooterLeftSide/Add";
import UpdateFooterLeftSide from "./components/Admin/footer/FooterLeftSide/Update";
import ManageFooterLeftSide from "./components/Admin/footer/FooterLeftSide/Manage";

import Website from "./components/Website/Website";
import AboutUs from "./components/Website/AboutUs";
import ContactUs from "./components/Website/ContactUs";
import ProjectDetail from "./components/Website/ProjectDetail";
import ServiceDetail from "./components/Website/ServiceDetail";
import Service from "./components/Website/Service";
import NotFound from "./components/common/NotFound";
import ProtectedRoute from "./components/common/ProtectedRoute";
import Login from "./components/Admin/User/Login";
import Signup from "./components/Admin/User/SignUp";
import ScrollOnTop from "./components/Website/Common/ScrollOnTop";
import ProjectsPage from "./components/Website/ProjectsPage";

function App() {
  return (
    <Router>
      <ScrollOnTop />
      <Routes>
        <Route path="/" element={<Website Component={Home} />} />
        <Route path="/about-us" element={<Website Component={AboutUs} />} />
        <Route
          path="/projects"
          element={<Website Component={ProjectsPage} />}
        />
        <Route
          path="/project-detail/:id"
          element={<Website Component={ProjectDetail} />}
        />
        <Route path="/services" element={<Website Component={Service} />} />
        <Route
          path="/service-detail/:id"
          element={<Website Component={ServiceDetail} />}
        />
        <Route path="/contact-us" element={<Website Component={ContactUs} />} />

        <Route element={<ProtectedRoute />}>
          {/* Dashboard */}
          <Route path="/admin" element={<Dashboard />} />
          {/* about banner */}
          <Route
            path="/admin/about/banner/add"
            element={<Dashboard Component={AddAboutBanner} />}
          />
          <Route
            path="/admin/about/banner/update/:id"
            element={<Dashboard Component={UpdateAboutBanner} />}
          />
          <Route
            path="/admin/about/banner/manage"
            element={<Dashboard Component={ManageAboutBanner} />}
          />
          {/* about header */}
          <Route
            path="/admin/about/header/add"
            element={<Dashboard Component={AddAboutHeader} />}
          />
          <Route
            path="/admin/about/header/update/:id"
            element={<Dashboard Component={UpdateAboutHeader} />}
          />
          <Route
            path="/admin/about/header/manage"
            element={<Dashboard Component={ManageAboutHeader} />}
          />
          {/* about features */}
          <Route
            path="/admin/about/features/add"
            element={<Dashboard Component={AddAboutFeatures} />}
          />
          <Route
            path="/admin/about/features/update/:id"
            element={<Dashboard Component={UpdateAboutFeatures} />}
          />
          <Route
            path="/admin/about/features/manage"
            element={<Dashboard Component={ManageAboutFeatures} />}
          />
          {/* about history - header */}
          <Route
            path="/admin/about/history-header/add"
            element={<Dashboard Component={AddAboutHistoryHeader} />}
          />
          <Route
            path="/admin/about/history-header/update/:id"
            element={<Dashboard Component={UpdateAboutHistoryHeader} />}
          />
          <Route
            path="/admin/about/history-header/manage"
            element={<Dashboard Component={ManageAboutHistoryHeader} />}
          />
          {/* about history - work */}
          <Route
            path="/admin/about/history-work/add"
            element={<Dashboard Component={AddAboutHistoryWork} />}
          />
          <Route
            path="/admin/about/history-work/update/:id"
            element={<Dashboard Component={UpdateAboutHistoryWork} />}
          />
          <Route
            path="/admin/about/history-work/manage"
            element={<Dashboard Component={ManageAboutHistoryWork} />}
          />
          {/* about choose - header */}
          <Route
            path="/admin/about/choose-header/add"
            element={<Dashboard Component={AddAboutChooseHeader} />}
          />
          <Route
            path="/admin/about/choose-header/update/:id"
            element={<Dashboard Component={UpdateAboutChooseHeader} />}
          />
          <Route
            path="/admin/about/choose-header/manage"
            element={<Dashboard Component={ManageAboutChooseHeader} />}
          />
          {/* about choose - service */}
          <Route
            path="/admin/about/choose-service/add"
            element={<Dashboard Component={AddAboutChooseService} />}
          />
          <Route
            path="/admin/about/choose-service/update/:id"
            element={<Dashboard Component={UpdateAboutChooseService} />}
          />
          <Route
            path="/admin/about/choose-service/manage"
            element={<Dashboard Component={ManageAboutChooseService} />}
          />

          {/* get in touch */}
          <Route
            path="/admin/get-in-touch/add"
            element={<Dashboard Component={AddGetInTouch} />}
          />
          <Route
            path="/admin/get-in-touch/update/:id"
            element={<Dashboard Component={UpdateGetInTouch} />}
          />
          <Route
            path="/admin/get-in-touch/manage"
            element={<Dashboard Component={ManageGetInTouch} />}
          />

          {/* subscribe */}
          <Route
            path="/admin/subscribe/add"
            element={<Dashboard Component={AddSubscribe} />}
          />
          <Route
            path="/admin/subscribe/update/:id"
            element={<Dashboard Component={UpdateSubscribe} />}
          />
          <Route
            path="/admin/subscribe/manage"
            element={<Dashboard Component={ManageSubscribe} />}
          />

          {/* home */}

          <Route
            path="/admin/home/slider/add"
            element={<Dashboard Component={AddHomeSlider} />}
          />
          <Route
            path="/admin/home/slider/update/:id"
            element={<Dashboard Component={UpdateHomeSlider} />}
          />
          <Route
            path="/admin/home/slider/manage"
            element={<Dashboard Component={ManageHomeSlider} />}
          />

          <Route
            path="/admin/home/header/add"
            element={<Dashboard Component={AddHomeHeader} />}
          />
          <Route
            path="/admin/home/header/update/:id"
            element={<Dashboard Component={UpdateHomeHeader} />}
          />
          <Route
            path="/admin/home/header/manage"
            element={<Dashboard Component={ManageHomeHeader} />}
          />

          <Route
            path="/admin/home-projects/header/add"
            element={<Dashboard Component={AddHomeProjectsHeader} />}
          />
          <Route
            path="/admin/home-projects/header/update/:id"
            element={<Dashboard Component={UpdateHomeProjectsHeader} />}
          />
          <Route
            path="/admin/home-projects/header/manage"
            element={<Dashboard Component={ManageHomeProjectsHeader} />}
          />

          <Route
            path="/admin/home/header-point/add"
            element={<Dashboard Component={AddHomeHeaderPoint} />}
          />
          <Route
            path="/admin/home/header-point/update/:id"
            element={<Dashboard Component={UpdateHomeHeaderPoint} />}
          />
          <Route
            path="/admin/home/header-point/manage"
            element={<Dashboard Component={ManageHomeHeaderPoint} />}
          />

          <Route
            path="/admin/home/service/add"
            element={<Dashboard Component={AddHomeService} />}
          />
          <Route
            path="/admin/home/service/update/:id"
            element={<Dashboard Component={UpdateHomeService} />}
          />
          <Route
            path="/admin/home/service/manage"
            element={<Dashboard Component={ManageHomeService} />}
          />

          <Route
            path="/admin/home/brand/add"
            element={<Dashboard Component={AddHomeBrand} />}
          />
          <Route
            path="/admin/home/brand/update/:id"
            element={<Dashboard Component={UpdateHomeBrand} />}
          />
          <Route
            path="/admin/home/brand/manage"
            element={<Dashboard Component={ManageHomeBrand} />}
          />

          {/* projects */}
          <Route
            path="/admin/project/banner/add"
            element={<Dashboard Component={AddProjectBanner} />}
          />
          <Route
            path="/admin/project/banner/update/:id"
            element={<Dashboard Component={UpdateProjectBanner} />}
          />
          <Route
            path="/admin/project/banner/manage"
            element={<Dashboard Component={ManageProjectBanner} />}
          />

          <Route
            path="/admin/project/add"
            element={<Dashboard Component={AddProject} />}
          />
          <Route
            path="/admin/project/update/:id"
            element={<Dashboard Component={UpdateProject} />}
          />
          <Route
            path="/admin/project/manage"
            element={<Dashboard Component={ManageProject} />}
          />

          <Route
            path="/admin/project/bottom/add"
            element={<Dashboard Component={AddProjectBottom} />}
          />
          <Route
            path="/admin/project/bottom/update/:id"
            element={<Dashboard Component={UpdateProjectBottom} />}
          />
          <Route
            path="/admin/project/bottom/manage"
            element={<Dashboard Component={ManageProjectBottom} />}
          />

          {/* contact us */}
          <Route
            path="/admin/contact-us/banner/add"
            element={<Dashboard Component={AddContactBanner} />}
          />
          <Route
            path="/admin/contact-us/banner/update/:id"
            element={<Dashboard Component={UpdateContactBanner} />}
          />
          <Route
            path="/admin/contact-us/banner/manage"
            element={<Dashboard Component={ManageContactBanner} />}
          />

          <Route
            path="/admin/contact-us/header/add"
            element={<Dashboard Component={AddContactHeader} />}
          />
          <Route
            path="/admin/contact-us/header/update/:id"
            element={<Dashboard Component={UpdateContactHeader} />}
          />
          <Route
            path="/admin/contact-us/header/manage"
            element={<Dashboard Component={ManageContactHeader} />}
          />

          <Route
            path="/admin/contact-us/form/add"
            element={<Dashboard Component={AddContactForm} />}
          />
          <Route
            path="/admin/contact-us/form/update/:id"
            element={<Dashboard Component={UpdateContactForm} />}
          />
          <Route
            path="/admin/contact-us/form/manage"
            element={<Dashboard Component={ManageContactForm} />}
          />

          <Route
            path="/admin/contact-us/footer/company/add"
            element={<Dashboard Component={AddFooterCompany} />}
          />
          <Route
            path="/admin/contact-us/footer/company/update/:id"
            element={<Dashboard Component={UpdateFooterCompany} />}
          />
          <Route
            path="/admin/contact-us/footer/company/manage"
            element={<Dashboard Component={ManageFooterCompany} />}
          />

          <Route
            path="/admin/contact-us/footer/country/add"
            element={<Dashboard Component={AddFooterCountry} />}
          />
          <Route
            path="/admin/contact-us/footer/country/update/:id"
            element={<Dashboard Component={UpdateFooterCountry} />}
          />
          <Route
            path="/admin/contact-us/footer/country/manage"
            element={<Dashboard Component={ManageFooterCountry} />}
          />

          <Route
            path="/admin/contact-us/footer/content/add"
            element={<Dashboard Component={AddContactFooterContent} />}
          />
          <Route
            path="/admin/contact-us/footer/content/update/:id"
            element={<Dashboard Component={UpdateContactFooterContent} />}
          />
          <Route
            path="/admin/contact-us/footer/content/manage"
            element={<Dashboard Component={ManageContactFooterContent} />}
          />
          {/* support */}
          <Route
            path="/admin/support/add"
            element={<Dashboard Component={AddSupport} />}
          />
          <Route
            path="/admin/support/update/:id"
            element={<Dashboard Component={UpdateSupport} />}
          />
          <Route
            path="/admin/support/manage"
            element={<Dashboard Component={ManageSupport} />}
          />

          {/* logo */}
          <Route
            path="/admin/logo/add"
            element={<Dashboard Component={AddLogo} />}
          />
          <Route
            path="/admin/logo/update/:id"
            element={<Dashboard Component={UpdateLogo} />}
          />
          <Route
            path="/admin/logo/manage"
            element={<Dashboard Component={ManageLogo} />}
          />

          {/* services */}
          <Route
            path="/admin/service/banner/add"
            element={<Dashboard Component={AddServiceBanner} />}
          />
          <Route
            path="/admin/service/banner/update/:id"
            element={<Dashboard Component={UpdateServiceBanner} />}
          />
          <Route
            path="/admin/service/banner/manage"
            element={<Dashboard Component={ManageServiceBanner} />}
          />

          <Route
            path="/admin/service/header/add"
            element={<Dashboard Component={AddServiceHeader} />}
          />
          <Route
            path="/admin/service/header/update/:id"
            element={<Dashboard Component={UpdateServiceHeader} />}
          />
          <Route
            path="/admin/service/header/manage"
            element={<Dashboard Component={ManageServiceHeader} />}
          />

          <Route
            path="/admin/service/add"
            element={<Dashboard Component={AddService} />}
          />
          <Route
            path="/admin/service/update/:id"
            element={<Dashboard Component={UpdateService} />}
          />
          <Route
            path="/admin/service/manage"
            element={<Dashboard Component={ManageService} />}
          />

          {/* choose us */}
          <Route
            path="/admin/choose-us/header/add"
            element={<Dashboard Component={AddChooseUsHeader} />}
          />
          <Route
            path="/admin/choose-us/header/update/:id"
            element={<Dashboard Component={UpdateChooseUsHeader} />}
          />
          <Route
            path="/admin/choose-us/header/manage"
            element={<Dashboard Component={ManageChooseUsHeader} />}
          />

          <Route
            path="/admin/choose-us/progress/add"
            element={<Dashboard Component={AddChooseUsProgress} />}
          />
          <Route
            path="/admin/choose-us/progress/update/:id"
            element={<Dashboard Component={UpdateChooseUsProgress} />}
          />
          <Route
            path="/admin/choose-us/progress/manage"
            element={<Dashboard Component={ManageChooseUsProgress} />}
          />

          <Route
            path="/admin/choose-us/fact/add"
            element={<Dashboard Component={AddChooseUsFact} />}
          />
          <Route
            path="/admin/choose-us/fact/update/:id"
            element={<Dashboard Component={UpdateChooseUsFact} />}
          />
          <Route
            path="/admin/choose-us/fact/manage"
            element={<Dashboard Component={ManageChooseUsFact} />}
          />

          {/* footer */}
          <Route
            path="/admin/footer/add"
            element={<Dashboard Component={AddFooterContent} />}
          />
          <Route
            path="/admin/footer/update/:id"
            element={<Dashboard Component={UpdateFooterContent} />}
          />
          <Route
            path="/admin/footer/manage"
            element={<Dashboard Component={ManageFooterContent} />}
          />

          <Route
            path="/admin/footer/category/add"
            element={<Dashboard Component={AddFooterCategory} />}
          />
          <Route
            path="/admin/footer/category/update/:id"
            element={<Dashboard Component={UpdateFooterCategory} />}
          />
          <Route
            path="/admin/footer/category/manage"
            element={<Dashboard Component={ManageFooterCategory} />}
          />

          <Route
            path="/admin/footer/left-side/add"
            element={<Dashboard Component={AddFooterLeftSide} />}
          />
          <Route
            path="/admin/footer/left-side/update/:id"
            element={<Dashboard Component={UpdateFooterLeftSide} />}
          />
          <Route
            path="/admin/footer/left-side/manage"
            element={<Dashboard Component={ManageFooterLeftSide} />}
          />
        </Route>
        <Route path="*" element={<NotFound />} />
        <Route path="/login" element={<Login />} />
        {/* <Route path="/signup" element={<Signup />} /> */}
      </Routes>
    </Router>
  );
}

export default App;
