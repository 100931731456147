import GetAllRecords from "../../../common/GetAllRecords";
import { useState } from "react";
import Table from "../../../common/Table";

const Manage = () => {
  const getRoute = "/api/contact-us/form/show-all";
  const deleteRoute = `/api/contact-us/form/delete/`;
  const linkToUpdateComponent = `/admin/contact-us/form/update/`;
  const [data, setData] = useState([]);

  const onDelete = async (itemId) => {
    setData((prevData) => prevData.filter((item) => item._id !== itemId));
  };

  return (
    <div className="container">
      <h3 className="text-center">Manage contact Us</h3>
      <GetAllRecords
        endpointToGetAllRecords={getRoute}
        endpointToDeleteRecord={deleteRoute}
        onDelete={onDelete}
      >
        {({ data, loading, handleDeleteInternal }) => (
          <>
            {loading ? (
              <p>Loading...</p>
            ) : (
              <Table
                data={data}
                handleDelte={handleDeleteInternal}
                updateLink={linkToUpdateComponent}
              />
            )}
          </>
        )}
      </GetAllRecords>
    </div>
  );
};

export default Manage;
