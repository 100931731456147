import axios from "axios";
import React, { useEffect, useState } from "react";
import Support from "../Common/Support";

const ChooseUs = () => {
  const [headerData, setHeaderData] = useState([]);
  const [progressData, setProgressData] = useState([]);
  const [factData, setFactData] = useState([]);

  useEffect(() => {
    const loadItems = async () => {
      const { data } = await axios.get("/api/chooseus/header/show-all");
      setHeaderData(data);
      console.log(data);
    };
    loadItems();
  }, []);

  useEffect(() => {
    const loadItems = async () => {
      const { data } = await axios.get("/api/chooseus/progress/show-all");
      setProgressData(data);
      console.log(data);
    };
    loadItems();
  }, []);

  useEffect(() => {
    const loadItems = async () => {
      const { data } = await axios.get("/api/chooseus/fact/show-all");
      setFactData(data);
      console.log("FACT", data);
    };
    loadItems();
  }, []);

  const bgImage = {
    backgroundImage: `url(/uploads/${
      headerData.length > 0 ? headerData[0].image : ""
    })`,
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  };

  return (
    <>
      <section className="fact-counter-section" style={bgImage}>
        <div className="container">
          <div className="sec-title text-center light">
            <h3>{headerData.length > 0 && headerData[0].heading}</h3>
          </div>
          {/* /.sec-title text-center light */}
          <div className="row">
            <div className="col-md-6 col-sm-12 col-xs-12">
              <div className="progress-box">
                <p>{headerData.length > 0 && headerData[0].description}</p>
                {progressData.slice(0, 6).map((d) => (
                  <div className="single-progress-bar">
                    <div className="labeled-text">{d.progressName}</div>
                    {/* /.labeled-text */}
                    <div className="progress-scale">
                      <div
                        className="inner pogress-animation slideInLeft"
                        style={{ width: `${d.percentage}%` }}
                      />
                      {/* /.inner */}
                    </div>
                    {/* /.progress-scale */}
                    <div className="percent">
                      <span
                        className="timer"
                        data-from={0}
                        data-to={d.percentage}
                        data-speed={3000}
                        data-refresh-interval={50}
                      />
                      {d.percentage}%
                    </div>
                    {/* /.percent */}
                  </div>
                ))}
                {/* /.single-progress-bar */}
              </div>
              {/* /.progress-box */}
            </div>
            {/* /.col-md-6 */}
            <div className="col-md-6 col-sm-12 col-xs-12">
              <div className="fact-counter row">
                <div className="divider hor" />
                {/* /.divider hor */}
                <div className="divider ver" />
                {/* /.divider hor */}
                {factData?.slice(0, 6).map((d) => (
                  <div className="col-md-6 col-sm-6 col-xs-12">
                    <div className="single-fact-counter">
                      <div className="icon-box">
                        <i className={d.iconClass} />
                      </div>
                      {/* /.icon-box */}
                      <div className="text-box">
                        <h3 className="counter">{d.count}</h3>
                        <p>{d.factName}</p>
                      </div>
                      {/* /.text-box */}
                    </div>
                    {/* /.single-fact-counter */}
                  </div>
                ))}
                {/* /.col-md-6 */}
              </div>
              {/* /.fact-counter */}
            </div>
            {/* /.col-md-6 */}
          </div>
          {/* /.row */}
        </div>
        {/* /.container */}
      </section>

      <div style={{ marginTop: "-80px" }}>
        <Support />
      </div>
    </>
  );
};

export default ChooseUs;
