import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import TextArea from "../../../common/TextArea";
import Input from "../../../common/Input";
import Button from "../../../common/Button";

const Update = () => {
  const [companyName, setCompanyName] = useState("");
  const { id } = useParams();

  // error messages
  const created = "Created Successfully";
  const errorMessage = "something Bad Happend";
  const companyNameError = "companyName is missing";

  const notifyCreate = (message) => toast.success(message);
  const notifyError = (message) => toast.error(message);

  const handleCompanyNameChange = (e) => {
    setCompanyName(e.target.value);
  };

  useEffect(() => {
    const loadItems = async () => {
      try {
        const response = await axios.get(
          `/api/contact-us/footer/company/get-single-item/${id}`
        );
        const itemData = response.data;

        console.log(itemData);
        setCompanyName(itemData.companyName);
      } catch (error) {
        console.log(error);
      }
    };
    loadItems();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    switch (true) {
      case !companyName:
        notifyError(companyNameError);
        break;

      default:
        try {
          // send a POST request to the server to add the product
          const response = await axios.post(
            `/api/contact-us/footer/company/update/${id}`,
            {
              companyName,
            }
          );
          notifyCreate(created);
          // handle the response and perform any necessary actions
          console.log(response);
          console.log(response.data);

          // reset the form
        } catch (error) {
          notifyError(errorMessage);
          console.error(error);
        }
        break;
    }
  };

  return (
    <div>
      <h3 className="text-center">Update Footer Company Name</h3>
      <form onSubmit={handleSubmit}>
        <Input
          value={companyName}
          onChange={handleCompanyNameChange}
          id="companyName"
          placeholder="Company Name"
          label="Company Name"
        />
        <div className="text-center">
          <Button name="Update" type="submit" className="btn btn-warning" />
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default Update;
