import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import TextArea from "../../../common/TextArea";
import Input from "../../../common/Input";
import Button from "../../../common/Button";
import IconsList from "../../../common/IconsList";

const Update = () => {
  const [progressName, setProgressName] = useState("");
  const [percentage, setPercentage] = useState("");
  const { id } = useParams();

  // error messages
  const created = "Created Successfully";
  const errorMessage = "something Bad Happend";
  const percentageError = "percentage is missing";
  const percentageNotValid = "please enter valid percentage between 1 to 100";
  const progressNameError = "progressName is missing";

  const notifyCreate = (message) => toast.success(message);
  const notifyError = (message) => toast.error(message);

  useEffect(() => {
    const loadItems = async () => {
      try {
        const response = await axios.get(
          `/api/chooseus/progress/get-single-item/${id}`
        );
        const itemData = response.data;

        console.log(itemData);
        setProgressName(itemData.progressName);
        setPercentage(itemData.percentage);
      } catch (error) {
        console.log(error);
      }
    };
    loadItems();
  }, [id]);

  const handleProgressNameChange = (e) => {
    setProgressName(e.target.value);
  };

  const handlePercentageChange = (e) => {
    setPercentage(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    switch (true) {
      case !progressName:
        notifyError(progressNameError);
        break;

      case !percentage:
        notifyError(percentageError);
        break;

      case percentage > 100:
        notifyError(percentageNotValid);
        break;

      case percentage < 1:
        notifyError(percentageNotValid);
        break;

      default:
        try {
          // send a POST request to the server to add the product
          const response = await axios.post(
            `/api/chooseus/progress/update/${id}`,
            {
              progressName,
              percentage,
            }
          );
          notifyCreate(created);
          // handle the response and perform any necessary actions
          console.log(response);
          console.log(response.data);

          // reset the form
        } catch (error) {
          notifyError(errorMessage);
          console.error(error);
        }
        break;
    }
  };

  return (
    <div>
      <h3 className="text-center">Update Choose Us Progress</h3>
      <form onSubmit={handleSubmit}>
        <Input
          value={progressName}
          onChange={handleProgressNameChange}
          id="floatingInputHeading"
          placeholder="Progress Name"
          label="Progress Name"
        />
        <Input
          type="number"
          value={percentage}
          onChange={handlePercentageChange}
          id="floatingInputHeading"
          placeholder="Percentage (please enter between 1 to 100)"
          label="Percentage"
        />
        <div className="text-center">
          <Button name="Update" type="submit" className="btn btn-warning" />
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default Update;
