import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import TextArea from "../../common/TextArea";
import Input from "../../common/Input";
import Button from "../../common/Button";

const UpdateAboutPage = () => {
  const [email, setEmail] = useState("");
  const { id } = useParams();

  const created = "Subscribed Successfully";
  const errorMessage = "something Bad Happend";
  const emailError = "email is missing";

  const notifyCreate = (message) => toast.success(message);
  const notifyError = (message) => toast.error(message);

  useEffect(() => {
    const loadItems = async () => {
      try {
        const response = await axios.get(
          `/api/subscribe/get-single-item/${id}`
        );
        const aboutData = response.data;

        setEmail(aboutData.email);
      } catch (error) {
        console.log(error);
      }
    };
    loadItems();
  }, [id]);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    switch (true) {
      case !email:
        notifyError(emailError);
        break;

      default:
        try {
          // send a POST request to the server to add the product
          const response = await axios.post(`/api/subscribe/update/${id}`, {
            email,
          });
          notifyCreate(created);
          // handle the response and perform any necessary actions
          console.log(response);
          console.log(response.data);

          // reset the form
        } catch (error) {
          notifyError(errorMessage);
          console.error(error);
        }
        break;
    }
  };

  return (
    <div>
      <h3 className="text-center">Update Feature</h3>
      <form onSubmit={handleSubmit}>
        <Input
          value={email}
          onChange={handleEmailChange}
          id="floatingInputEmail"
          placeholder="Email"
          label="Email"
        />
        <div className="text-center">
          <Button name="Update" type="submit" className="btn btn-warning" />
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default UpdateAboutPage;
