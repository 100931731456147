import React, { useState } from "react";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import Input from "../../common/Input";
import Button from "../../common/Button";
import TextArea from "../../common/TextArea";

const Add = () => {
  const [email, setEmail] = useState("");

  // error messages
  const created = "Subscribed Successfully";
  const errorMessage = "something Bad Happend";
  const emailError = "email is missing";

  const notifyCreate = (message) => toast.success(message);
  const notifyError = (message) => toast.error(message);

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    switch (true) {
      case !email:
        notifyError(emailError);
        break;

      default:
        try {
          // send a POST request to the server to add the product
          const response = await axios.post("/api/subscribe/add", {
            email,
          });
          notifyCreate(created);
          // handle the response and perform any necessary actions
          console.log(response);
          console.log(response.data);

          // reset the form
          setEmail("");
        } catch (error) {
          notifyError(errorMessage);
          console.error(error);
        }
        break;
    }
  };

  return (
    <div>
      <h3 className="text-center">Add Features</h3>
      <form onSubmit={handleSubmit}>
        <Input
          type="email"
          value={email}
          onChange={handleEmailChange}
          id="floatingInputEmail"
          placeholder="Email"
          label="Email"
        />
        <div className="text-center">
          <Button name="Submit" type="submit" className="btn btn-primary" />
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default Add;
