import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

const Support = () => {
  const [support, setSupport] = useState([]);

  useEffect(() => {
    const loadItems = async () => {
      const { data } = await axios.get("/api/support/show-all");
      setSupport(data);
      console.log(data);
    };
    loadItems();
  }, []);
  return (
    <section className="feature-style-one">
      <div className="container">
        <div className="clearfix">
          {support?.map((d, index) => (
            <div className="col-md-4" key={index}>
              <div
                className={`single-feature-style-one ${
                  index === 0
                    ? "bg-dark light"
                    : "" || index === 1 || index === 2
                    ? "light"
                    : ""
                }`}
              >
                <div className="icon-box">
                  <i className={d.iconClass} />
                </div>
                {/* /.icon-box */}
                <div className="text-box">
                  <h3>{d.heading}</h3>
                  <p>{d.description}</p>
                </div>
                {/* /.text-box */}
              </div>
              {/* /.single-feature-style-one */}
            </div>
          ))}
        </div>
        {/* /.row */}
      </div>
      {/* /.container */}
    </section>
  );
};

export default Support;
