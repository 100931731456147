import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import TextArea from "../../../common/TextArea";
import Input from "../../../common/Input";
import Button from "../../../common/Button";

const Update = () => {
  const [companyName, setCompanyName] = useState("");
  const [videoUrl, setvideoUrl] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState(null);
  const [fileSizeError, setFileSizeError] = useState(false);
  const [existingImage, setExistingImage] = useState("");
  const { id } = useParams();

  const created = "Updated Successfully";
  const errorMessage = "something Bad Happend";
  const companyNameError = "companyName is missing";
  const descriptionError = "Description is missing";
  const videoUrlError = "videoUrl is missing";
  const imageError = "image is missing";
  const imageSizeError = "Please choose file less than 5 MB";

  const notifyCreate = (message) => toast.success(message);
  const notifyError = (message) => toast.error(message);

  useEffect(() => {
    const loadItems = async () => {
      try {
        const response = await axios.get(
          `/api/home/header/get-single-item/${id}`
        );
        const itemData = response.data;

        console.log(itemData);
        setCompanyName(itemData.companyName);
        setvideoUrl(itemData.videoUrl);
        setDescription(itemData.description);
        setExistingImage(`/uploads/${itemData.image}`);
      } catch (error) {
        console.log(error);
      }
    };
    loadItems();
  }, [id]);

  const handleImageChange = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      if (selectedFile.size > 5 * 1024 * 1024) {
        setFileSizeError(true);
        try {
          notifyError(imageSizeError);
        } catch (error) {
          console.log(error);
        }
      } else {
        setFileSizeError(false);
        setImage(selectedFile || existingImage);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("companyName", companyName);
    formData.append("videoUrl", videoUrl);
    formData.append("description", description);
    formData.append("image", image || existingImage);

    if (!fileSizeError) {
      switch (true) {
        case !videoUrl:
          notifyError(videoUrlError);
          break;

        case !companyName:
          notifyError(companyNameError);
          break;

        case !description:
          notifyError(descriptionError);
          break;

        default:
          try {
            // send a POST request to the server to add the product
            const response = await axios.post(
              `/api/home/header/update/${id}`,
              formData
            );
            notifyCreate(created);
            // handle the response and perform any necessary actions
            console.log(response);
            console.log(response.data);

            // reset the form
          } catch (error) {
            notifyError(errorMessage);
            console.error(error);
          }
          break;
      }
    }
  };
  const handlecompanyNameChange = (e) => {
    setCompanyName(e.target.value);
  };

  const handlevideoUrlChange = (e) => {
    setvideoUrl(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  return (
    <div>
      <h3 className="text-center">Update Feature</h3>
      <form onSubmit={handleSubmit}>
        <Input
          value={videoUrl}
          onChange={handlevideoUrlChange}
          id="floatingInputvideoUrl"
          placeholder="videoUrl"
          label="videoUrl"
        />
        <Input
          value={companyName}
          onChange={handlecompanyNameChange}
          id="floatingInputcompanyName"
          placeholder="companyName"
          label="companyName"
        />
        <TextArea
          name="Description"
          value={description}
          onChange={handleDescriptionChange}
          id="floatingDescription2"
          boxHeight="5rem"
          label="Description"
        />
        <Input
          type="file"
          onChange={handleImageChange}
          id="floatingInputImage"
          placeholder="Image"
          label="Image - Please select file less than 5 MB - 353 x 353"
        />
        <div className="text-center">
          <Button name="Update" type="submit" className="btn btn-warning" />
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default Update;
