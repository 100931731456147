import React from "react";
import { Link } from "react-router-dom";

const CustomDropDown = ({ buttonName, addRecordPath, manageRecordPath }) => {
  return (
    <div className="btn-group">
      <button
        type="button"
        className="btn dropdown-toggle"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        {buttonName}
      </button>
      <ul className="dropdown-menu">
        <li>
          <Link className="dropdown-item " to={addRecordPath}>
            Add
          </Link>
        </li>
        <li>
          <Link className="dropdown-item" to={manageRecordPath}>
            Manage
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default CustomDropDown;
