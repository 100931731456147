import React, { useState } from "react";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import Input from "../../../common/Input";
import Button from "../../../common/Button";
import IconsList from "../../../common/IconsList";
import DataList from "../../../common/DataList";

const Add = () => {
  const [factName, setFactName] = useState("");
  const [count, setCount] = useState("");
  const [iconClass, setIconClass] = useState("");

  const iconClassNames = [
    "industrio-icon-emails-interface-download-symbol",
    "industrio-icon-pdf",
    "industrio-icon-menu",
    "industrio-icon-next",
    "industrio-icon-coffee-cup",
    "industrio-icon-team",
    "industrio-icon-child",
    "industrio-icon-play-button",
    "industrio-icon-wind-engine",
    "industrio-icon-settings-1",
    "industrio-icon-delivery-truck",
    "industrio-icon-support",
    "industrio-icon-secure-shield",
    "industrio-icon-atomic",
    "industrio-icon-plant",
    "industrio-icon-settings",
    "industrio-icon-safety",
    "industrio-icon-drop-of-liquid",
    "industrio-icon-scythe",
    "industrio-icon-factory-1",
    "industrio-icon-green-energy",
    "industrio-icon-innovation",
    "industrio-icon-gas-station-1",
    "industrio-icon-layers",
    "industrio-icon-atom",
    "industrio-icon-flasks",
    "industrio-icon-gas-station",
    "industrio-icon-gas-pipe",
    "industrio-icon-tower",
    "industrio-icon-valve",
    "industrio-icon-industry",
    "industrio-icon-worker",
    "industrio-icon-envelope",
    "industrio-icon-clock",
    "industrio-icon-phone-call",
    "industrio-icon-right-quote",
  ];
  // error messages
  const created = "Created Successfully";
  const iconCopied = "icon Copied";
  const errorMessage = "something Bad Happend";
  const countError = "count is missing";
  const factNameError = "factName is missing";
  const iconClassError = "icon is missing";

  const notifyCreate = (message) => toast.success(message);
  const notifyError = (message) => toast.error(message);

  const handleCopyClick = (text) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        // successful copy
        notifyCreate(iconCopied);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleFactNameChange = (e) => {
    setFactName(e.target.value);
  };

  const handleIconClassChange = (e) => {
    setIconClass(e.target.value);
  };

  const handleCountChange = (e) => {
    setCount(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    switch (true) {
      case !factName:
        notifyError(factNameError);
        break;

      case !count:
        notifyError(countError);
        break;

      case !iconClass:
        notifyError(iconClassError);
        break;

      default:
        try {
          // send a POST request to the server to add the product
          const response = await axios.post("/api/chooseus/fact/add", {
            factName,
            iconClass,
            count,
          });
          notifyCreate(created);
          // handle the response and perform any necessary actions
          console.log(response);
          console.log(response.data);

          // reset the form
          setFactName("");
          setIconClass("");
          setCount("");
        } catch (error) {
          notifyError(errorMessage);
          console.error(error);
        }
        break;
    }
  };

  return (
    <div>
      <h3 className="text-center">Add Choose Us Fact</h3>
      <form onSubmit={handleSubmit}>
        <Input
          value={factName}
          onChange={handleFactNameChange}
          id="Fact Name"
          placeholder="Fact Name"
          label="Fact Name"
        />
        <Input
          value={count}
          type="number"
          onChange={handleCountChange}
          id="Count"
          placeholder="Count Please Enter between 1 to 100"
          label="Count"
        />
        <DataList
          value={iconClass}
          onChange={handleIconClassChange}
          id="floatingInputIconClass"
          placeholder="IconClass"
          label="IconClass"
          listData={iconClassNames}
        />

        <div className="text-center">
          <Button name="Submit" type="submit" className="btn btn-primary" />
        </div>
      </form>
      <IconsList handleCopyClick={handleCopyClick} />
      <ToastContainer />
    </div>
  );
};

export default Add;
