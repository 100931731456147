import axios from "axios";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import FooterMenu from "./Components/FooterMenu";

const Footer = () => {
  const [logo, setLogo] = useState([]);
  const [footerLeftSide, setFooterLeftSide] = useState({});
  const [projectsBottom, setProjectsBottom] = useState([]);
  const currentYear = new Date().getFullYear();

  useEffect(() => {
    const loadItems = async () => {
      const { data } = await axios.get("/api/footer/left-side/show-all");
      setFooterLeftSide(data[0]);
      console.log(data);
    };
    loadItems();
  }, []);

  useEffect(() => {
    const loadItems = async () => {
      const { data } = await axios.get("/api/logo/show-all");
      setLogo(data);
      console.log(data);
    };
    loadItems();
  }, []);

  useEffect(() => {
    const loadItems = async () => {
      const { data } = await axios.get("/api/project/bottom/show-all");
      setProjectsBottom(data);
      console.log(data);
    };
    loadItems();
  }, []);

  const getImageFilename = (imageUrl) => {
    const urlParts = imageUrl.split("/");
    const filename = urlParts[urlParts.length - 1]; // Get the last part which is the filename
    return filename;
  };

  return (
    <>
      <footer className="site-footer">
        <div className="main-footer">
          <div className="container-fluid" style={{ padding: "0 50px" }}>
            <div className="row">
              <div className="col-md-3 col-sm-6 col-xs-12">
                <div className="footer-widget about-widget">
                  {logo.length > 0 && (
                    <Link to="/">
                      <img
                        className="img-fluid col-8"
                        src={`/uploads/${logo[0].image}`}
                      />
                    </Link>
                  )}
                  <h3>{footerLeftSide && footerLeftSide.heading}</h3>
                  <p>{footerLeftSide && footerLeftSide.description}</p>
                </div>
                {/* /.footer-widget about-widget */}
              </div>
              {/* /.col-md-3 */}
              <FooterMenu />
            </div>
            {/* /.row */}
          </div>
          {/* /.container */}
        </div>
        {/* /.main-footer */}
        <div className="bottom-footer">
          <div className="container">
            <div className="left-text pull-left">
              <p>
                © Copyright Voltonic Solution {currentYear}. All®Rights Are
                Reserved.
              </p>
            </div>
            {/* /.left-text */}
            <div className="right-text pull-right">
              <p>Developed by: Voltonic Solution Tech Team</p>
            </div>
            {/* /.right-text */}
          </div>
          {/* /.container */}
        </div>
        {/* /.bottom-footer */}
      </footer>

      <section className="hidden-sidebar side-navigation">
        <Link
          to="/"
          className="close-button side-navigation-close-btn fa fa-times"
        />
        {/* /.close-button */}
        <div className="sidebar-content">
          <div className="top-content">
            {logo.length > 0 && (
              <Link to="/">
                <img
                  className="img-fluid"
                  src={`/uploads/${logo[0].image}`}
                  alt={getImageFilename(logo[0].image)}
                  style={{ width: "15rem" }}
                />
              </Link>
            )}
          </div>
          {/* /.top-content */}
          <nav className="nav-menu middle-content">
            <ul className="navigation-box">
              <li className="current">
                <Link to="/">
                  Home <span className="subnav-toggler fa fa-caret-down" />
                </Link>
              </li>
              <li>
                <Link to="/">About Us</Link>{" "}
              </li>
              <li>
                <Link to="/services">
                  Services <span className="subnav-toggler fa fa-caret-down" />
                </Link>
              </li>
              <li>
                <Link to="/projects">
                  Projects <span className="subnav-toggler fa fa-caret-down" />
                </Link>
              </li>
              <li>
                <Link to="/contact-us">Contact Us</Link>{" "}
              </li>
            </ul>
          </nav>
          {/* /.nav-menu */}
          {projectsBottom.length > 0 ? (
            <div className="bottom-content">
              <div className="social">
                {projectsBottom[0].facebook && (
                  <a
                    target="_blank"
                    href={projectsBottom[0].facebook}
                    className="fab fa-facebook"
                  />
                )}
                {projectsBottom[0].twitter && (
                  <a
                    target="_blank"
                    href={projectsBottom[0].twitter}
                    className="fab fa-twitter-square"
                  />
                )}
                {projectsBottom[0].instagram && (
                  <a
                    target="_blank"
                    href={projectsBottom[0].instagram}
                    className="fab fa-instagram"
                  />
                )}
                {projectsBottom[0].linkedin && (
                  <a
                    target="_blank"
                    href={projectsBottom[0].linkedin}
                    className="fab fa-linkedin"
                  />
                )}
              </div>
              {/* /.social */}
              <p className="copy-text">
                © {currentYear} Voltonic Solution <br /> Developed{" "}
                <i className="fa fa-heart" /> by Voltonic Solution Tech Team
              </p>
              {/* /.copy-text */}
            </div>
          ) : (
            ""
          )}
          {/* /.bottom-content */}
        </div>
        {/* /.sidebar-content */}
      </section>
    </>
  );
};

export default Footer;
