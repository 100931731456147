import axios from "axios";
import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";

const FooterMenuCompany = () => {
  const [contactCompany, setContactCompany] = useState([]);

  useEffect(() => {
    const loadItems = async () => {
      const { data } = await axios.get(
        "/api/contact-us/footer/content/show-all"
      );
      setContactCompany(data);
      console.log(data);
    };
    loadItems();
  }, []);

  return (
    <footer className="site-footer">
      <div className="container-fluid bg-dark" style={{ padding: "0 50px" }}>
        <div className="row p-5">
          {/* /.col-md-3 */}
          {contactCompany.map((d) => (
            <div className="col-md-2 col-sm-6 col-xs-12">
              <div className="footer-widget services-widget">
                {/* /.title */}

                <p className="mb-3 text-light ms-4">{d.companyName}</p>
                <div className="title">
                  <p className="text-light ms-4">{d.countryName}</p>
                </div>
                <ul className="links-list">
                  {d?.options.map((option, index) => (
                    <li key={index}>
                      <div className="d-flex">
                        {index === 0 && (
                          <FontAwesomeIcon
                            style={{ color: "#005da2" }}
                            size="2xl"
                            icon={faMapMarkerAlt}
                          />
                        )}

                        <p className="text-light">
                          <span>&nbsp;&nbsp;</span>
                          {option.optionName}
                        </p>
                      </div>
                    </li>
                  ))}
                </ul>
                {/* /.links-list */}
              </div>
              {/* /.footer-widget services-widget */}
            </div>
          ))}
          {/* /.col-md-4 */}
        </div>
        {/* /.row */}
      </div>
      {/* /.container */}
    </footer>
  );
};

export default FooterMenuCompany;
