import React, { useState } from "react";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import Input from "../../../common/Input";
import Button from "../../../common/Button";

const Add = () => {
  const route = "/api/project/bottom/add";
  const [userData, setUserData] = useState({
    title: "",
    description: "",
    videoUrl: "",
    facebook: "",
    twitter: "",
    instagram: "",
    linkedin: "",
  });

  const [image, setImage] = useState(null);
  const [fileSizeError, setFileSizeError] = useState(false);

  const toastMessages = {
    created: "Created Successfully",
    error: "something Bad Happend",
    title: "Title is missing",
    description: "Description is missing",
    imageError: "image is missing",
    imageSizeError: "Please choose file less than 5 MB",
  };

  const notifyCreate = (message) => toast.success(message);
  const notifyError = (message) => toast.error(message);

  const inputHandler = (event) => {
    const { name, value } = event.target;
    setUserData({ ...userData, [name]: value });
  };

  const handleImageChange = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile && selectedFile.size > 5 * 1024 * 1024) {
      setFileSizeError(true);
      try {
        notifyError(toastMessages.imageSizeError);
      } catch (error) {
        console.log(error);
      }
    } else {
      setFileSizeError(false);
      setImage(selectedFile);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { title, description } = userData;

    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    formData.append("image", image);

    if (!fileSizeError) {
      switch (true) {
        case !title:
          notifyError(toastMessages.title);
          break;

        case !description:
          notifyError(toastMessages.description);
          break;

        case !image:
          notifyError(toastMessages.imageError);
          break;

        default:
          try {
            // send a POST request to the server to add the product
            const response = await axios.post(route, formData);
            notifyCreate(toastMessages.created);
            // handle the response and perform any necessary actions
            console.log(response);
            console.log(response.data);

            // reset the form
            setUserData({
              title: "",
              description: "",
              videoUrl: "",
              facebook: "",
              twitter: "",
              instagram: "",
              linkedin: "",
            });
          } catch (error) {
            notifyError(toastMessages.error);
            console.error(error);
          }
          break;
      }
    }
  };

  return (
    <div className="container">
      <h3 className="text-center">Add Project - Bottom Area</h3>
      <div className="row">
        <form onSubmit={handleSubmit}>
          <tr className="d-flex justify-content-center">
            <td className="col-6">
              <Input
                value={userData.title}
                onChange={inputHandler}
                id="title"
                placeholder="Title"
                label="Title *"
                name="title"
              />
              <Input
                value={userData.description}
                onChange={inputHandler}
                id="description"
                placeholder="Description"
                label="Description *"
                name="description"
              />
              <Input
                value={userData.videoUrl}
                onChange={inputHandler}
                id="videoUrl"
                placeholder="Video Url"
                label="Video Url"
                name="videoUrl"
              />
              <Input
                value={userData.facebook}
                onChange={inputHandler}
                id="facebook"
                placeholder="Facebook"
                label="Facebook"
                name="facebook"
              />
            </td>
            <span className="m-2"></span>
            <td className="col-6">
              <Input
                value={userData.instagram}
                onChange={inputHandler}
                id="instagram"
                placeholder="Instagram"
                label="Instagram"
                name="instagram"
              />
              <Input
                value={userData.twitter}
                onChange={inputHandler}
                id="twitter"
                placeholder="Twitter"
                label="Twitter"
                name="twitter"
              />
              <Input
                value={userData.linkedin}
                onChange={inputHandler}
                id="linkedin"
                placeholder="Linkedin"
                label="Linkedin"
                name="linkedin"
              />
              <Input
                type="file"
                onChange={handleImageChange}
                id="Image"
                placeholder="Image"
                label="Image * - Please select file less than 5 MB - 1920 x 607"
              />
            </td>
          </tr>
          <div className="text-center">
            <Button name="Submit" type="submit" className="btn btn-primary" />
          </div>
        </form>
        <ToastContainer />
      </div>
    </div>
  );
};

export default Add;
