import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import Slider from "react-slick";

const Projects = () => {
  const [projects, setProjects] = useState([]);
  const [projectsheadingData, setprojectsHeadingData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("All Projects");
  const sliderRef = useRef();

  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 5, // Number of categories to show at a time
    slidesToScroll: 1,
    centerMode: false,
    variableWidth: true,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024, // Adjust this breakpoint as needed
        settings: {
          slidesToShow: 5, // Number of categories to show on mobile screens
        },
      },
      {
        breakpoint: 768, // Adjust this breakpoint as needed
        settings: {
          slidesToShow: 4, // Number of categories to show on mobile screens
        },
      },
      {
        breakpoint: 425, // Adjust this breakpoint as needed
        settings: {
          slidesToShow: 1, // Number of categories to show on mobile screens
        },
      },
      {
        breakpoint: 375, // Adjust this breakpoint as needed
        settings: {
          slidesToShow: 1, // Number of categories to show on mobile screens
        },
      },
      {
        breakpoint: 320, // Adjust this breakpoint as needed
        settings: {
          slidesToShow: 1, // Number of categories to show on mobile screens
        },
      },
    ],
  };

  const groupProjectsByCategory = (projects) => {
    return projects.reduce((grouped, project) => {
      if (!grouped[project.categoryName]) {
        grouped[project.categoryName] = [];
      }
      grouped[project.categoryName].push(project);
      return grouped;
    }, {});
  };

  const groupedProjects = groupProjectsByCategory(projects);

  const filterProjects = (category) => {
    setSelectedCategory(category);
  };

  const filteredProjects =
    selectedCategory === "All Projects"
      ? projects
      : projects.filter((project) => project.categoryName === selectedCategory);

  useEffect(() => {
    const loadItems = async () => {
      const { data } = await axios.get("/api/home-projects/header/show-all");
      setprojectsHeadingData(data);
      console.log(data);
    };
    loadItems();
  }, []);

  useEffect(() => {
    const loadItems = async () => {
      const { data } = await axios.get("/api/project/show-all");
      setProjects(data);
      console.log(data);
    };
    loadItems();
  }, []);

  return (
    <>
      <div className="container-fluid">
        <section className="portfolio-style-two full-width no-gutter sec-pad">
          <div className="container-fluid">
            {projectsheadingData.length > 0 ? (
              <div className="sec-title text-center">
                <h3>{projectsheadingData[0].heading}</h3>
                <p>{projectsheadingData[0].description}</p>
              </div>
            ) : (
              ""
            )}
            {/* /.sec-title */}
            <div id="projects-area" className="gallery-filter">
              <div className="container-fluid">
                <div className="row d-flex justify-content-between mb-2">
                  <div className="col-10 me-0 p-0">
                    <ul className="text-center bg-light border border-2 rounded-pill shadow-lg">
                      <Slider ref={sliderRef} {...settings}>
                        <li
                          className={`"filter ${
                            selectedCategory === "All Projects"
                              ? "portfolio-style-twoactive projects__category-button-bg-color rounded-pill"
                              : ""
                          }`}
                          onClick={() => filterProjects("All Projects")}
                        >
                          <span
                            className={`${
                              selectedCategory === "All Projects"
                                ? "text-white"
                                : "text-dark"
                            }`}
                          >
                            <i className="industrio-icon-layers" />
                            All Projects
                          </span>
                        </li>
                        {/*
                         */}
                        {Object.keys(groupedProjects)?.map((categoryName) => (
                          <li
                            className={
                              selectedCategory === categoryName
                                ? "active projects__category-button-bg-color rounded-pill shadow text-white"
                                : ""
                            }
                            onClick={() => filterProjects(categoryName)}
                            key={categoryName}
                          >
                            <span
                              className={`${
                                selectedCategory === categoryName
                                  ? "text-white"
                                  : "text-dark"
                              }`}
                            >
                              {/* <i className="industrio-icon-drop-of-liquid" /> */}
                              {categoryName}
                            </span>
                          </li>
                        ))}
                        {/*
                         */}
                      </Slider>
                    </ul>
                  </div>
                </div>
              </div>

              {/* /.post-filter */}
            </div>
            {/* /.gallery-filter */}
            <div className="container-fluid p-3">
              <div className="row">
                {filteredProjects?.map((d) => (
                  <div
                    className={`col-md-3 col-sm-6 col-xs-12 single-filter-item p-3 ${d.categoryName}`}
                    key={d._id}
                  >
                    <Link to={`/project-detail/${d._id}`}>
                      <div className="single-portfolio-style-two">
                        <div className="img-box">
                          <img
                            src={`/uploads/${d.image}`}
                            alt={d.title}
                            className="img-fluid"
                            style={{ height: "400px", objectFit: "cover" }}
                          />
                          <div className="overlay">
                            <div className="box">
                              <div className="content">
                                <span className="fs-3 text-decoration-none">
                                  {d.title.substring(0, 60)}...
                                </span>
                                <Link
                                  className="text-decoration-none"
                                  to={`/project-detail/${d._id}`}
                                >
                                  <h3 className="fs-3 text-decoration-none">
                                    {d.heading.substring(0, 30)}...
                                  </h3>
                                </Link>
                                <Link
                                  to={`/project-detail/${d._id}`}
                                  className="img-popup industrio-icon-next text-decoration-none"
                                />
                              </div>
                              {/* /.content */}
                            </div>
                            {/* /.box */}
                          </div>
                          {/* /.overlay */}
                        </div>
                        {/* /.img-box */}
                      </div>
                      {/* /.single-portfolio-style-two */}
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </div>
          {/* /.container */}
        </section>
      </div>
      {/* /.project-style-one */}
    </>
  );
};

export default Projects;
