import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Input from "../../../common/Input";
import Button from "../../../common/Button";

const Update = () => {
  const [userData, setUserData] = useState({
    title: "",
    description: "",
    videoUrl: "",
    facebook: "",
    twitter: "",
    instagram: "",
    linkedin: "",
  });
  const [image, setImage] = useState(null);
  const [existingImage, setExistingImage] = useState("");
  const [fileSizeError, setFileSizeError] = useState(false);
  const { id } = useParams();

  const inputHandler = (event) => {
    const { name, value } = event.target;
    setUserData({ ...userData, [name]: value });
  };

  const toastMessages = {
    created: "Updated Successfully",
    error: "something Bad Happend",
    title: "Title is missing",
    description: "Description is missing",
    imageError: "image is missing",
    imageSizeError: "Please choose file less than 5 MB",
  };

  const notifyCreate = (message) => toast.success(message);
  const notifyError = (message) => toast.error(message);

  useEffect(() => {
    const loadItems = async () => {
      try {
        const response = await axios.get(
          `/api/project/bottom/get-single-item/${id}`
        );
        const itemData = response.data;

        setUserData({
          title: itemData.title,
          description: itemData.description,
          videoUrl: itemData.videoUrl,
          facebook: itemData.facebook,
          twitter: itemData.twitter,
          instagram: itemData.instagram,
          linkedin: itemData.linkedin,
        });
        console.log(itemData);
        setExistingImage(`/uploads/${itemData.image}`);
      } catch (error) {
        console.log(error);
      }
    };
    loadItems();
  }, [id]);

  const handleImageChange = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      if (selectedFile.size > 5 * 1024 * 1024) {
        setFileSizeError(true);
        try {
          notifyError(toastMessages.imageSizeError);
        } catch (error) {
          console.log(error);
        }
      } else {
        setFileSizeError(false);
        setImage(selectedFile || existingImage);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const {
      title,
      description,
      videoUrl,
      facebook,
      twitter,
      instagram,
      linkedin,
    } = userData;

    const formData = new FormData();
    formData.append("title", title);
    formData.append("description", description);
    formData.append("videoUrl", videoUrl);
    formData.append("facebook", facebook);
    formData.append("twitter", twitter);
    formData.append("instagram", instagram);
    formData.append("linkedin", linkedin);
    formData.append("image", image || existingImage);

    if (!fileSizeError) {
      switch (true) {
        case !title:
          notifyError(toastMessages.title);
          break;

        case !description:
          notifyError(toastMessages.description);
          break;

        default:
          try {
            // send a POST request to the server to add the product
            const response = await axios.post(
              `/api/project/bottom/update/${id}`,
              formData
            );
            notifyCreate(toastMessages.created);
            // handle the response and perform any necessary actions
            console.log(response);
            console.log(response.data);
          } catch (error) {
            notifyError(toastMessages.error);
            console.error(error);
          }
          break;
      }
    }
  };

  return (
    <div className="container">
      <h3 className="text-center">Update Project - Bottom Area</h3>
      <div className="row">
        <form onSubmit={handleSubmit}>
          <tr className="d-flex justify-content-center">
            <td className="col-6">
              <Input
                value={userData.title}
                onChange={inputHandler}
                id="title"
                placeholder="Title"
                label="Title *"
                name="title"
              />
              <Input
                value={userData.description}
                onChange={inputHandler}
                id="description"
                placeholder="Description"
                label="Description *"
                name="description"
              />
              <Input
                value={userData.videoUrl}
                onChange={inputHandler}
                id="videoUrl"
                placeholder="Video Url"
                label="Video Url"
                name="videoUrl"
              />
              <Input
                value={userData.facebook}
                onChange={inputHandler}
                id="facebook"
                placeholder="Facebook"
                label="Facebook"
                name="facebook"
              />
              <Input
                value={userData.twitter}
                onChange={inputHandler}
                id="twitter"
                placeholder="Twitter"
                label="Twitter"
                name="twitter"
              />
            </td>
            <span className="m-2"></span>
            <td className="col-6">
              <Input
                value={userData.instagram}
                onChange={inputHandler}
                id="instagram"
                placeholder="Instagram"
                label="Instagram"
                name="instagram"
              />
              <Input
                value={userData.linkedin}
                onChange={inputHandler}
                id="linkedin"
                placeholder="Linkedin"
                label="Linkedin"
                name="linkedin"
              />
              <div className="d-flex">
                {existingImage && (
                  <img
                    src={existingImage}
                    alt={existingImage}
                    className="img-fluid mb-2 col-1 me-3"
                  />
                )}
                {
                  <Input
                    type="file"
                    onChange={handleImageChange}
                    id="floatingInputImage"
                    placeholder="Image"
                    label="Image - Please select file less than 5 MB"
                    name={existingImage}
                  />
                }
              </div>
            </td>
          </tr>

          <div className="text-center">
            <Button name="Update" type="submit" className="btn btn-warning" />
          </div>
        </form>
        <ToastContainer />
      </div>
    </div>
  );
};

export default Update;
