import React from "react";

const DataList = ({
  divClassName = "mb-3",
  type = "text",
  label,
  value,
  onChange,
  id,
  placeholder,
  disabled,
  defaultValue,
  accept,
  maxLength,
  pattern,
  listData = [
    "industrio-icon-emails-interface-download-symbol",
    "industrio-icon-pdf",
    "industrio-icon-menu",
    "industrio-icon-next",
    "industrio-icon-coffee-cup",
    "industrio-icon-team",
    "industrio-icon-child",
    "industrio-icon-play-button",
    "industrio-icon-wind-engine",
    "industrio-icon-settings-1",
    "industrio-icon-delivery-truck",
    "industrio-icon-support",
    "industrio-icon-secure-shield",
    "industrio-icon-atomic",
    "industrio-icon-plant",
    "industrio-icon-settings",
    "industrio-icon-safety",
    "industrio-icon-drop-of-liquid",
    "industrio-icon-scythe",
    "industrio-icon-factory-1",
    "industrio-icon-green-energy",
    "industrio-icon-innovation",
    "industrio-icon-gas-station-1",
    "industrio-icon-layers",
    "industrio-icon-atom",
    "industrio-icon-flasks",
    "industrio-icon-gas-station",
    "industrio-icon-gas-pipe",
    "industrio-icon-tower",
    "industrio-icon-valve",
    "industrio-icon-industry",
    "industrio-icon-worker",
    "industrio-icon-envelope",
    "industrio-icon-clock",
    "industrio-icon-phone-call",
    "industrio-icon-right-quote",
  ],
}) => {
  return (
    <div className={divClassName}>
      <label htmlFor={id} className="form-label">
        {label}
      </label>
      <input
        value={value}
        onChange={onChange}
        type={type}
        className="form-control form-control-sm"
        id={id}
        aria-describedby={id}
        placeholder={placeholder}
        disabled={disabled}
        defaultValue={defaultValue}
        accept={accept}
        maxLength={maxLength}
        pattern={pattern}
        list="datalistOptions"
      />
      <datalist id="datalistOptions">
        {listData?.map((item) => (
          <option value={item}></option>
        ))}
      </datalist>
    </div>
  );
};

export default DataList;
