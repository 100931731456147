import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import BrandSlider from "./BrandSlider";
import HomeSlider from "./Common/HomeSlider";
import ServicesSlider from "./Components/ServicesSlider";
import ChooseUs from "./Components/ChooseUs";
import Projects from "./Components/Projects";
import FooterMenuCompany from "./Components/FooterMenuCompany";
import Map from "./Components/Map";
import GetInTouch from "./Common/GetInTouch";
import ProjectsSlider from "./Components/ProjectsSlider";

const Home = () => {
  const [services, setServices] = useState([]);
  const [brands, setBrands] = useState([]);
  const sliderRef = useRef();

  useEffect(() => {
    const loadItems = async () => {
      const { data } = await axios.get("/api/service/show-all");
      setServices(data);
      console.log(data);
    };
    loadItems();
  }, []);

  useEffect(() => {
    const loadItems = async () => {
      const { data } = await axios.get("/api/home/brand/show-all");
      setBrands(data);
      console.log(data);
    };
    loadItems();
  }, []);

  return (
    <>
      {/* /.header */}
      <HomeSlider />

      {/* /.about-style-one */}
      <ServicesSlider />

      <ChooseUs />

      <ProjectsSlider />
      {/* /.project-style-one */}

      {/* /.testimonials-style-two */}

      {/* /.feature-style-one */}

      {/* /.team-style-one */}

      {/* /.faq-rqa-section */}

      {/* /.blog-style-one */}

      <BrandSlider brands={brands} />

      <Map />
      <FooterMenuCompany />
      <GetInTouch />
      {/* /.brand-carousel-area */}
    </>
  );
};

export default Home;
