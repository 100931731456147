import React, { useState } from "react";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import Input from "../../../common/Input";
import Button from "../../../common/Button";
import TextArea from "../../../common/TextArea";

const Add = () => {
  const [yourName, setYourName] = useState("");
  const [email, setEmail] = useState("");
  const [location, setLocation] = useState("");
  const [message, setMessage] = useState("");

  // error messages
  const created = "Created Successfully";
  const errorMessage = "something Bad Happend";
  const yourNameError = "Your Name is missing";
  const emailError = "Email is missing";
  const locationError = "Location is missing";
  const messageError = "Message is missing";

  const notifyCreate = (message) => toast.success(message);
  const notifyError = (message) => toast.error(message);

  const handleYourNameChange = (e) => {
    setYourName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleLocationChange = (e) => {
    setLocation(e.target.value);
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    switch (true) {
      case !yourName:
        notifyError(yourNameError);
        break;

      case !email:
        notifyError(emailError);
        break;

      case !location:
        notifyError(locationError);
        break;

      case !message:
        notifyError(messageError);
        break;

      default:
        try {
          // send a POST request to the server to add the product
          const response = await axios.post("/api/contact-us/form/add", {
            yourName,
            email,
            location,
            message,
          });
          notifyCreate(created);
          // handle the response and perform any necessary actions
          console.log(response);
          console.log(response.data);

          // reset the form
          setYourName("");
          setEmail("");
          setLocation("");
          setMessage("");
        } catch (error) {
          notifyError(errorMessage);
          console.error(error);
        }
        break;
    }
  };

  return (
    <div>
      <h3 className="text-center">Add Contact Us</h3>
      <form onSubmit={handleSubmit}>
        <Input
          value={yourName}
          onChange={handleYourNameChange}
          id="floatingInputYourName"
          placeholder="YourName"
          label="YourName"
        />
        <Input
          type="email"
          value={email}
          onChange={handleEmailChange}
          id="floatingInputEmail"
          placeholder="Email"
          label="Email"
        />
        <Input
          value={location}
          onChange={handleLocationChange}
          id="floatingInputLocation"
          placeholder="Location"
          label="Location"
        />
        <TextArea
          name="Message"
          value={message}
          onChange={handleMessageChange}
          id="floatingMessage2"
          boxHeight="5rem"
        />
        <div className="text-center">
          <Button name="Submit" type="submit" className="btn btn-primary" />
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default Add;
