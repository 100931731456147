import React, { useState } from "react";
import Button from "./Button";
import CopyToClipboard from "./CopyToClipBoard";
import { ToastContainer, toast } from "react-toastify";

const IconsList = () => {
  const [active, setActive] = useState(false);

  const toastMessages = {
    iconCopied: "icon Copied",
  };

  const handleToogle = () => {
    setActive(!active);
  };

  const iconClassNames = [
    "industrio-icon-emails-interface-download-symbol",
    "industrio-icon-pdf",
    "industrio-icon-menu",
    "industrio-icon-next",
    "industrio-icon-coffee-cup",
    "industrio-icon-team",
    "industrio-icon-child",
    "industrio-icon-play-button",
    "industrio-icon-wind-engine",
    "industrio-icon-settings-1",
    "industrio-icon-delivery-truck",
    "industrio-icon-support",
    "industrio-icon-secure-shield",
    "industrio-icon-atomic",
    "industrio-icon-plant",
    "industrio-icon-settings",
    "industrio-icon-safety",
    "industrio-icon-drop-of-liquid",
    "industrio-icon-scythe",
    "industrio-icon-factory-1",
    "industrio-icon-green-energy",
    "industrio-icon-innovation",
    "industrio-icon-gas-station-1",
    "industrio-icon-layers",
    "industrio-icon-atom",
    "industrio-icon-flasks",
    "industrio-icon-gas-station",
    "industrio-icon-gas-pipe",
    "industrio-icon-tower",
    "industrio-icon-valve",
    "industrio-icon-industry",
    "industrio-icon-worker",
    "industrio-icon-envelope",
    "industrio-icon-clock",
    "industrio-icon-phone-call",
    "industrio-icon-right-quote",
  ];

  return (
    <div>
      <Button
        name="Available Icon Classes"
        onClick={handleToogle}
        className="fw-bold btn btn-info text-white mb-3"
      />
      {active && (
        <div className="row">
          <div className="col-md-6">
            {iconClassNames
              .slice(0, iconClassNames.length / 2)
              .map((iconClassName, index) => (
                <ul className="list-group ">
                  <li
                    key={index}
                    className="list-group-item d-flex justify-content-between"
                  >
                    {iconClassName}

                    <i className={iconClassName}> preview</i>
                    {/* <CopyToClipboard textToCopy={iconClassName} /> */}
                  </li>
                </ul>
              ))}
          </div>
          <div className="col-md-6">
            {iconClassNames
              .slice(iconClassNames.length / 2)
              .map((iconClassName, index) => (
                <ul className="list-group ">
                  <li
                    key={index}
                    className="list-group-item d-flex justify-content-between"
                  >
                    {iconClassName}

                    <i className={iconClassName}> preview</i>
                    {/* <CopyToClipboard textToCopy={iconClassName} /> */}
                  </li>
                </ul>
              ))}
          </div>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default IconsList;
