import React from "react";

const TextArea = ({
  name,
  value,
  onChange,
  id,
  boxHeight = "5rem",
  placeholder = "Description",
  disabled,
  label,
}) => {
  return (
    <div className="mb-3">
      <label htmlFor={id} className="form-label">
        {label}
      </label>
      <textarea
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        id={id}
        className="form-control form-control-sm"
        style={{ height: boxHeight }}
        disabled={disabled}
        name={name}
      ></textarea>
    </div>
  );
};

export default TextArea;
