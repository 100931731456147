import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import TextArea from "../../../common/TextArea";
import Input from "../../../common/Input";
import Button from "../../../common/Button";

const Update = () => {
  const [image, setImage] = useState(null);
  const [existingImage, setExistingImage] = useState("");
  const [fileSizeError, setFileSizeError] = useState(false);
  const { id } = useParams();

  const created = "Created Successfully";
  const errorMessage = "something Bad Happend";
  const imageError = "image is missing";
  const imageSizeError = "Please choose file less than 5 MB";

  const notifyCreate = (message) => toast.success(message);
  const notifyError = (message) => toast.error(message);

  useEffect(() => {
    const loadItems = async () => {
      try {
        const response = await axios.get(
          `/api/about/banner/get-single-item/${id}`
        );
        const data = response.data;

        console.log(data);
        setExistingImage(`/uploads/${data.image}`);
      } catch (error) {
        console.log(error);
      }
    };
    loadItems();
  }, [id]);

  const handleImageChange = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      if (selectedFile.size > 5 * 1024 * 1024) {
        setFileSizeError(true);
        try {
          notifyError(imageSizeError);
        } catch (error) {
          console.log(error);
        }
      } else {
        setFileSizeError(false);
        setImage(selectedFile || existingImage);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("image", image || existingImage);

    if (!fileSizeError) {
      switch (true) {
        default:
          try {
            // send a POST request to the server to add the product
            const response = await axios.post(
              `/api/about/banner/update/${id}`,
              formData
            );
            notifyCreate(created);
            // handle the response and perform any necessary actions
            console.log(response);
            console.log(response.data);

            // reset the form
          } catch (error) {
            notifyError(errorMessage);
            console.error(error);
          }
          break;
      }
    }
  };

  return (
    <div>
      <h3 className="text-center">Update Banner</h3>
      <form onSubmit={handleSubmit}>
        <div className="d-flex">
          {existingImage && (
            <img
              src={existingImage}
              alt={existingImage}
              className="img-fluid mb-2 col-1"
            />
          )}
          {
            <Input
              type="file"
              onChange={handleImageChange}
              id="floatingInputImage"
              placeholder="Image"
              label="Image - Please select file less than 5 MB"
              name={existingImage}
              defaultValue={existingImage}
            />
          }
        </div>

        <div className="text-center">
          <Button name="Update" type="submit" className="btn btn-warning" />
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default Update;
