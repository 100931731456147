import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Input from "../../../common/Input";
import Button from "../../../common/Button";
import Select from "../../../common/Select";

const Update = () => {
  const [companyName, setCompanyName] = useState("");
  const [countryName, setCountryName] = useState("");
  const [options, setOptions] = useState([]);
  const [optionName, setOptionName] = useState("");
  const [optionLink, setOptionLink] = useState("");
  const [companyNames, setCompanyNames] = useState([]);
  const [countryNames, setCountryNames] = useState([]);
  const { id } = useParams();

  // error messages
  const created = "Created Successfully";
  const errorMessage = "something Bad Happend";
  const optionsError = "options is missing";
  const companyNameError = "companyName is missing";
  const countryNameError = "companyName is missing";

  const notifyCreate = (message) => toast.success(message);
  const notifyError = (message) => toast.error(message);

  const handleCompanyNameChange = (e) => {
    setCompanyName(e.target.value);
  };

  const handleCountryNameChange = (e) => {
    setCountryName(e.target.value);
  };

  const handleOptionNameChange = (e) => {
    setOptionName(e.target.value);
  };

  const handleOptionLinkChange = (e) => {
    setOptionLink(e.target.value);
  };

  const handleAddOption = () => {
    if (optionName) {
      setOptions([...options, { optionName, optionLink }]);
      setOptionName("");
      setOptionLink("");
    }
  };

  const handleRemoveOption = (index) => {
    // remove an option from the options arrays based on its index
    const newOptions = [...options];
    newOptions.splice(index, 1);
    setOptions(newOptions);
  };

  useEffect(() => {
    const loadItems = async () => {
      const { data } = await axios.get(
        "/api/contact-us/footer/company/show-all"
      );
      setCompanyNames(data);
      console.log("company", data.companyName);
      console.log("company", data);
    };
    loadItems();
  }, []);

  useEffect(() => {
    const loadItems = async () => {
      const { data } = await axios.get(
        "/api/contact-us/footer/country/show-all"
      );
      setCountryNames(data);
      console.log("country", data.countryName);
      console.log("country", data);
    };
    loadItems();
  }, []);

  useEffect(() => {
    const loadItems = async () => {
      try {
        const response = await axios.get(
          `/api/contact-us/footer/content/get-single-item/${id}`
        );
        const itemData = response.data;
        console.log(itemData);
        setCompanyName(itemData.companyName);
        setCountryName(itemData.countryName);
        setOptions(itemData.options);
      } catch (error) {
        console.log(error);
      }
    };
    loadItems();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    switch (true) {
      case !companyName:
        notifyError(companyNameError);
        break;

      case !countryName:
        notifyError(countryNameError);
        break;

      default:
        try {
          // send a POST request to the server to add the product
          const response = await axios.post(
            `/api/contact-us/footer/content/update/${id}`,
            {
              companyName,
              countryName,
              options,
            }
          );
          notifyCreate(created);
          // handle the response and perform any necessary actions
          console.log(response);
          console.log(response.data);

          // reset the form
        } catch (error) {
          notifyError(errorMessage);
          console.error(error);
        }
        break;
    }
  };

  return (
    <div>
      <h3 className="text-center">Update Footer For Contact Us Page</h3>
      <form onSubmit={handleSubmit}>
        <Select
          name="Choose Company Name"
          options={companyNames.map((item) => item.companyName)}
          id="companyName"
          onChange={handleCompanyNameChange}
          placeholder="Company Name"
          label="Company Name"
          value={companyName}
        />

        <Select
          name="Choose Country Name"
          options={countryNames.map((item) => item.countryName)}
          id="countryName"
          onChange={handleCountryNameChange}
          placeholder="Country Name"
          label="Country Name"
          value={countryName}
        />

        <div>
          <Input
            value={optionName}
            onChange={handleOptionNameChange}
            id="optionName"
            placeholder="Option Name"
            label="Option Name"
          />
          {/* <Input
            value={optionLink}
            onChange={handleOptionLinkChange}
            id="optionLink"
            placeholder="Option Link"
            label="Option Link"
          /> */}
          <Button
            className="btn btn-secondary"
            name="Add Option"
            type="button"
            onClick={handleAddOption}
          />
        </div>
        <div>
          <div className="col-4">
            <table class="table table-hover">
              <thead>
                <tr>
                  <th scope="col">Option Name</th>
                  <th scope="col">Option Link</th>
                  <th scope="col">Operation</th>
                </tr>
              </thead>
              <tbody>
                {options?.map((option, index) => (
                  <tr>
                    <td>{option.optionName || "N/A"}</td>
                    <td>{option.optionLink || "N/A"}</td>
                    <td>
                      <Button
                        type="button"
                        name="Remove Option"
                        className="btn btn-danger"
                        onClick={() => handleRemoveOption(index)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="text-center">
          <Button name="Update" type="submit" className="btn btn-warning" />
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default Update;
