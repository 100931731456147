import React from "react";

const Select = ({ name, id, value, onChange, options, label }) => {
  return (
    <div className="form-floating mb-3">
      <select
        className="form-select"
        id={id}
        aria-label="Floating label select example"
        value={value}
        onChange={onChange}
        label={label}
      >
        <option defaultValue>{label}</option>
        {options?.map((item,index) => (
          <option key={index} value={item}>{item}</option>
        ))}
      </select>
      <label htmlFor={id}>{name}</label>
    </div>
  );
};

export default Select;
