import React, { useState } from "react";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import Input from "../../../common/Input";
import Button from "../../../common/Button";
import TextArea from "../../../common/TextArea";

const Add = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  // error messages
  const created = "Created Successfully";
  const errorMessage = "something Bad Happend";
  const descriptionError = "Description is missing";
  const titleError = "title is missing";

  const notifyCreate = (message) => toast.success(message);
  const notifyError = (message) => toast.error(message);

  const handletitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    switch (true) {
      case !title:
        notifyError(titleError);
        break;

      case !description:
        notifyError(descriptionError);
        break;

      default:
        try {
          // send a POST request to the server to add the product
          const response = await axios.post("/api/about/choose-header/add", {
            title,
            description,
          });
          notifyCreate(created);
          // handle the response and perform any necessary actions
          console.log(response);
          console.log(response.data);

          // reset the form
          setTitle("");
          setDescription("");
        } catch (error) {
          notifyError(errorMessage);
          console.error(error);
        }
        break;
    }
  };

  return (
    <div>
      <h3 className="text-center">Add Choose Us - Header</h3>
      <form onSubmit={handleSubmit}>
        <Input
          value={title}
          onChange={handletitleChange}
          id="floatingInputtitle"
          placeholder="title"
          label="title"
        />
        <TextArea
          name="Description"
          value={description}
          onChange={handleDescriptionChange}
          id="floatingDescription2"
          boxHeight="5rem"
        />
        <div className="text-center">
          <Button name="Submit" type="submit" className="btn btn-primary" />
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default Add;
