import axios from "axios";
import React, { useState, useEffect } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import the CSS
import { Link } from "react-router-dom";

const HomeSlider = () => {
  const [homeSlider, setHomeSlider] = useState([]);

  useEffect(() => {
    const loadItems = async () => {
      const { data } = await axios.get("/api/home/slider/show-all");
      setHomeSlider(data);
      console.log(data);
    };
    loadItems();
  }, []);

  return (
    <>
      <div
        id="minimal-bootstrap-carousel"
        className="carousel slide carousel-fade slider-home-one slider-home-two"
        data-ride="carousel"
      >
        {/* Wrapper for slides */}
        <div className="carousel-inner" role="listbox">
          {homeSlider?.map((slider, index) => (
            <div
              className={`item ${index === 0 ? "active" : ""} slide-${
                index + 1
              }`}
              style={{
                backgroundImage: `url(/uploads/${slider.image})`,
                backgroundPosition: "center center",
              }}
            >
              <div className="carousel-caption d-flex justify-content-center align-items-center flex-column">
                <div className="container-fluid ">
                  <div class="row">
                    <div className="col-12">
                      <div className="box valign-middle">
                        {/* for mobile screen */}
                        <div className="content text-center d-sm-none d-block">
                          <h2
                            data-animation="animated fadeInUp"
                            style={{
                              marginLeft: "auto",
                              marginRight: "auto",
                            }}
                          >
                            {slider.heading}
                          </h2>
                          <p
                            data-animation="animated fadeInDown"
                            style={{
                              marginLeft: "auto",
                              marginRight: "auto",
                            }}
                          >
                            {slider.description}
                          </p>
                          <Link
                            to={slider.url}
                            className="banner-btn hvr-sweep-to-right text-decoration-none"
                            data-animation="animated fadeInDown"
                          >
                            Learn more <i className="fa fa-arrow-right" />
                          </Link>
                        </div>
                        {/* for desktop screen */}
                        <div className="content text-center d-sm-block d-none">
                          <h2
                            data-animation="animated fadeInUp"
                            style={{
                              fontSize: "70px",
                              padding: "0px 100px",
                              marginLeft: "auto",
                              marginRight: "auto",
                            }}
                          >
                            {slider.heading}
                          </h2>
                          <div className="row">
                            <div
                              className="col-12 d-flex justify-content-center fs-1"
                              style={{
                                padding: "30px 200px",
                                marginLeft: "auto",
                                marginRight: "auto",
                              }}
                            >
                              <span data-animation="animated fadeInDown">
                                {slider.description}
                              </span>
                            </div>
                          </div>
                          <Link
                            to={slider.url}
                            className="banner-btn hvr-sweep-to-right text-decoration-none"
                            data-animation="animated fadeInDown"
                          >
                            Learn more <i className="fa fa-arrow-right" />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
        {/* Controls */}
        <a
          className="left carousel-control"
          href="#minimal-bootstrap-carousel"
          role="button"
          data-slide="prev"
        >
          <i className="fas fa-angle-left" />
          <span className="sr-only">Previous</span>
        </a>
        <a
          className="right carousel-control"
          href="#minimal-bootstrap-carousel"
          role="button"
          data-slide="next"
        >
          <i className="fas fa-angle-right" />
          <span className="sr-only">Next</span>
        </a>
        <ul className="carousel-indicators list-inline custom-navigation">
          {homeSlider.map((slider, index) => (
            <li
              key={index}
              data-target="#minimal-bootstrap-carousel"
              data-slide-to={index}
              className={index === 0 ? "active" : ""}
            />
          ))}
        </ul>
        {/* /.logo home-two */}
      </div>
    </>
  );
};

export default HomeSlider;

