import React, { useState } from "react";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import Input from "../../../common/Input";
import Button from "../../../common/Button";
import TextArea from "../../../common/TextArea";

const Add = () => {
  const [heading, setHeading] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState(null);
  const [fileSizeError, setFileSizeError] = useState(false);

  // error messages
  const created = "Created Successfully";
  const errorMessage = "something Bad Happend";
  const descriptionError = "Description is missing";
  const headingError = "Heading is missing";
  const imageError = "image is missing";
  const imageSizeError = "Please choose file less than 5 MB";

  const notifyCreate = (message) => toast.success(message);
  const notifyError = (message) => toast.error(message);

  const handleImageChange = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile && selectedFile.size > 5 * 1024 * 1024) {
      setFileSizeError(true);
      try {
        notifyError(imageSizeError);
      } catch (error) {
        console.log(error);
      }
    } else {
      setFileSizeError(false);
      setImage(selectedFile);
    }
  };

  const handleHeadingChange = (e) => {
    setHeading(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("heading", heading);
    formData.append("description", description);
    formData.append("image", image);

    if (!fileSizeError) {
      switch (true) {
        case !heading:
          notifyError(headingError);
          break;

        case !description:
          notifyError(descriptionError);
          break;

        case !image:
          notifyError(imageError);
          break;

        default:
          try {
            // send a POST request to the server to add the product
            const response = await axios.post(
              "/api/chooseus/header/add",
              formData
            );
            notifyCreate(created);
            // handle the response and perform any necessary actions
            console.log(response);
            console.log(response.data);

            // reset the form
            setHeading("");
            setDescription("");
            setImage(null);
          } catch (error) {
            notifyError(errorMessage);
            console.error(error);
          }
          break;
      }
    }
  };

  return (
    <div>
      <h3 className="text-center">Add Choose Us Header</h3>
      <form onSubmit={handleSubmit}>
        <Input
          value={heading}
          onChange={handleHeadingChange}
          id="floatingInputHeading"
          placeholder="Heading"
          label="Heading"
        />
        <TextArea
          label="Description"
          name="Description"
          value={description}
          onChange={handleDescriptionChange}
          id="floatingDescription2"
          boxHeight="5rem"
        />
        <Input
          type="file"
          onChange={handleImageChange}
          id="floatingInputImage"
          placeholder="Image"
          label="Image - Please select file less than 5 MB - 1920 x 1093"
        />
        <div className="text-center">
          <Button name="Submit" type="submit" className="btn btn-primary" />
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default Add;
