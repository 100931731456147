import React from "react";
import Button from "./Button";
import { Link } from "react-router-dom";

const Table = ({ data, handleDelte, updateLink }) => {
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "2-digit", day: "2-digit" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const columns =
    data.length > 0
      ? Object.keys(data[0]).filter(
          (column) => column !== "_id" && column !== "__v"
        )
      : [];

  return (
    <table className="table table-striped  table-hover">
      <thead>
        <tr>
          {columns.map((column, index) => (
            <th key={index} scope="col-1">
              {column}
            </th>
          ))}
          <th scope="col">Operations</th>
        </tr>
      </thead>
      <tbody>
        {data.map((row, rowIndex) => (
          <tr key={rowIndex}>
            {columns.map((column, colIndex) => (
              <td key={colIndex} className="col-1">
                {column === "image" ? (
                  <span>
                    <img
                      src={`/uploads/${row[column]}`}
                      alt="image"
                      className="col-6 bg-primary"
                      style={{ borderRadius: "50%" }}
                    />
                  </span>
                ) : column === "projectDate" ? (
                  formatDate(row[column])
                ) : column === "categoryName" ? (
                  row[column].length > 10 ? (
                    `${row[column].substring(0, 20)}...`
                  ) : (
                    row[column]
                  )
                ) : column === "heading" ? (
                  row[column].length > 10 ? (
                    `${row[column].substring(0, 20)}...`
                  ) : (
                    row[column]
                  )
                ) : column === "title" ? (
                  row[column].length > 10 ? (
                    `${row[column].substring(0, 20)}...`
                  ) : (
                    row[column]
                  )
                ) : column === "description" ? (
                  row[column].length > 10 ? (
                    `${row[column].substring(0, 20)}...`
                  ) : (
                    row[column]
                  )
                ) : (
                  row[column]
                )}
              </td>
            ))}
            <td className="col-3">
              <Button
                className="btn btn-sm btn-danger"
                onClick={() => handleDelte(row._id)}
                name="Delete"
              />
              <span className="me-2 ms-2">|</span>
              <Link
                to={`${updateLink}${row._id}`}
                className="btn btn-sm btn-warning"
              >
                Edit
              </Link>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

export default Table;
