import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import TextArea from "../../../common/TextArea";
import Input from "../../../common/Input";
import Button from "../../../common/Button";

const UpdateAboutPage = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const { id } = useParams();

  // error messages
  const created = "Created Successfully";
  const errorMessage = "something Bad Happend";
  const descriptionError = "Description is missing";
  const titleError = "title is missing";

  const notifyCreate = (message) => toast.success(message);
  const notifyError = (message) => toast.error(message);

  useEffect(() => {
    const loadItems = async () => {
      try {
        const response = await axios.get(
          `/api/about/history-header/get-single-item/${id}`
        );
        const aboutData = response.data;

        console.log(aboutData);
        setTitle(aboutData.title);
        setDescription(aboutData.description);
      } catch (error) {
        console.log(error);
      }
    };
    loadItems();
  }, [id]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    switch (true) {
      case !title:
        notifyError(titleError);
        break;

      case !description:
        notifyError(descriptionError);
        break;

      default:
        try {
          // send a POST request to the server to add the product
          const response = await axios.post(
            `/api/about/history-header/update/${id}`,
            {
              title,
              description,
            }
          );
          notifyCreate(created);
          // handle the response and perform any necessary actions
          console.log(response);
          console.log(response.data);

          // reset the form
        } catch (error) {
          notifyError(errorMessage);
          console.error(error);
        }
        break;
    }
  };

  return (
    <div>
      <h3 className="text-center">Update History Header</h3>
      <form onSubmit={handleSubmit}>
        <Input
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          id="floatingInputtitle"
          placeholder="title"
          label="title"
        />
        <TextArea
          name="Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          id="floatingDescription2"
          boxHeight="5rem"
        />
        <div className="text-center">
          <Button name="Update" type="submit" className="btn btn-warning" />
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default UpdateAboutPage;
