import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const FooterMenu = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    const loadItems = async () => {
      const { data } = await axios.get("/api/footer/show-all");
      setData(data);
      console.log(data);
    };
    loadItems();
  }, []);

  return (
    <>
      {data?.map((d) => (
        <div className="col-md-2 col-sm-6 col-xs-12">
          <div className="footer-widget services-widget">
            <div className="title">
              <h3>{d.categoryName}</h3>
            </div>
            {/* /.title */}
            <ul className="links-list">
              {d.options.map((d) => (
                <li>
                  <Link className="text-decoration-none" to={d.optionLink}>
                    {d.optionName}
                  </Link>
                </li>
              ))}
            </ul>
            {/* /.links-list */}
          </div>
          {/* /.footer-widget services-widget */}
        </div>
      ))}
    </>
  );
};

export default FooterMenu;
