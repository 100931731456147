import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GetInTouch from "./Common/GetInTouch";
import FooterMenuCompany from "./Components/FooterMenuCompany";
import Map from "./Components/Map";

const ContactUs = () => {
  const [bannerImage, setBannerImage] = useState([]);
  const [headerData, setHeaderData] = useState([]);

  // for contact form
  const [yourName, setYourName] = useState("");
  const [email, setEmail] = useState("");
  const [location, setLocation] = useState("");
  const [message, setMessage] = useState("");

  // error messages
  const created = "Message Sent Successfully";
  const errorMessage = "something Bad Happend";
  const yourNameError = "Name is missing";
  const emailError = "Email is missing";
  const locationError = "Location is missing";
  const messageError = "Message is missing";

  const notifyCreate = (message) =>
    toast.success(message, { position: "bottom-right" });

  const notifyError = (message) =>
    toast.error(message, { position: "bottom-right" });

  const handleYourNameChange = (e) => {
    setYourName(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleLocationChange = (e) => {
    setLocation(e.target.value);
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    switch (true) {
      case !yourName:
        notifyError(yourNameError);
        break;

      case !email:
        notifyError(emailError);
        break;

      case !location:
        notifyError(locationError);
        break;

      case !message:
        notifyError(messageError);
        break;

      default:
        try {
          // send a POST request to the server to add the product
          const response = await axios.post("/api/contact-us/form/add", {
            yourName,
            email,
            location,
            message,
          });
          notifyCreate(created);
          // handle the response and perform any necessary actions
          console.log(response);
          console.log(response.data);

          // reset the form
          setYourName("");
          setEmail("");
          setLocation("");
          setMessage("");
        } catch (error) {
          notifyError(errorMessage);
          console.error(error);
        }
        break;
    }
  };

  useEffect(() => {
    const loadItems = async () => {
      const { data } = await axios.get("/api/contact-us/header/show-all");
      setHeaderData(data);
      console.log(data);
    };
    loadItems();
  }, []);

  useEffect(() => {
    const loadItems = async () => {
      const { data } = await axios.get("/api/contact-us/banner/show-all");
      setBannerImage(data);
      console.log(data);
    };
    loadItems();
  }, []);

  const bannerStyle = {
    backgroundImage: `url(/uploads/${
      bannerImage.length > 0 ? bannerImage[0].image : ""
    })`,
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  };

  return (
    <>
      <div className="inner-banner" style={bannerStyle}>
        <div className="container">
          <h3>Contact Us</h3>
          <ul className="breadcumb">
            <li>
              <Link className="text-decoration-none" to="/">
                Home
              </Link>
            </li>
            {/*
             */}
            <li>
              <span className="sep">
                <i className="fa fa-angle-right" />
              </span>
            </li>
            {/*
             */}
            <li>
              <span>Contact Us</span>
            </li>
          </ul>
          {/* /.breadcumb */}
        </div>
        {/* /.container */}
      </div>
      {/* /.inner-banner */}

      <section className="contact-page mb-5">
        <div className="container">
          {/* {headerData.length > 0 ? (
            <div className="sec-title text-center">
              <h3>{headerData[0].heading}</h3>
              <p>{headerData[0].description}</p>
            </div>
          ) : (
            ""
          )} */}
          {/* /.sec-title text-center */}
          <div className="container">
            <div className="row d-flex justify-content-between">
              <div
                className="col-md-6 col-md-12 p-5 mt-3"
                style={{ backgroundColor: "#d3d9ea" }}
              >
                <form onSubmit={handleSubmit} className="contact-form">
                  <h3>Send Mail</h3>
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Your name.."
                    name="name"
                    value={yourName}
                    onChange={handleYourNameChange}
                  />
                  <input
                    className="form-control"
                    type="email"
                    placeholder="Your email address.."
                    name="email"
                    value={email}
                    onChange={handleEmailChange}
                  />
                  <input
                    className="form-control"
                    type="text"
                    placeholder="Your Location.."
                    name="subject"
                    value={location}
                    onChange={handleLocationChange}
                  />
                  <textarea
                    placeholder="Write..."
                    className="form-control"
                    name="message"
                    value={message}
                    onChange={handleMessageChange}
                  />
                  <button type="submit" className="hvr-sweep-to-right">
                    Send
                  </button>
                </form>
                <ToastContainer />
              </div>

              {/* /.col-md-6 */}
            </div>
          </div>

          {/* /.row */}
        </div>
        <Map />
        {/* /.container */}
      </section>
      {/* /.contact-page */}

      {/* contact details */}

      <FooterMenuCompany />

      <GetInTouch />
      {/* /.contact-info-style-one */}
    </>
  );
};

export default ContactUs;
