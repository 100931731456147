import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import TextArea from "../../../common/TextArea";
import Input from "../../../common/Input";
import Button from "../../../common/Button";

const Update = () => {
  const [title, setTitle] = useState("");
  const [heading, setHeading] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState(null);
  const [existingImage, setExistingImage] = useState("");
  const [fileSizeError, setFileSizeError] = useState(false);
  const { id } = useParams();

  const created = "Created Successfully";
  const errorMessage = "something Bad Happend";
  const titleError = "Title is missing";
  const descriptionError = "Description is missing";
  const headingError = "Heading is missing";
  const imageError = "image is missing";
  const imageSizeError = "Please choose file less than 5 MB";

  const notifyCreate = (message) => toast.success(message);
  const notifyError = (message) => toast.error(message);

  useEffect(() => {
    const loadItems = async () => {
      try {
        const response = await axios.get(
          `/api/about/header/get-single-item/${id}`
        );
        const itemData = response.data;

        console.log(itemData);
        setTitle(itemData.title);
        setHeading(itemData.heading);
        setDescription(itemData.description);
        setExistingImage(`/uploads/${itemData.image}`);
      } catch (error) {
        console.log(error);
      }
    };
    loadItems();
  }, [id]);

  const handleImageChange = (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile) {
      if (selectedFile.size > 5 * 1024 * 1024) {
        setFileSizeError(true);
        try {
          notifyError(imageSizeError);
        } catch (error) {
          console.log(error);
        }
      } else {
        setFileSizeError(false);
        setImage(selectedFile || existingImage);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("title", title);
    formData.append("heading", heading);
    formData.append("description", description);
    formData.append("image", image || existingImage);

    if (!fileSizeError) {
      switch (true) {
        case !heading:
          notifyError(headingError);
          break;

        case !title:
          notifyError(titleError);
          break;

        case !description:
          notifyError(descriptionError);
          break;

        default:
          try {
            // send a POST request to the server to add the product
            const response = await axios.post(
              `/api/about/header/update/${id}`,
              formData
            );
            notifyCreate(created);
            // handle the response and perform any necessary actions
            console.log(response);
            console.log(response.data);

            // reset the form
          } catch (error) {
            notifyError(errorMessage);
            console.error(error);
          }
          break;
      }
    }
  };

  return (
    <div>
      <h3 className="text-center">Update Header</h3>
      <form onSubmit={handleSubmit}>
        <Input
          value={heading}
          onChange={(e) => setHeading(e.target.value)}
          id="floatingInputHeading"
          placeholder="Heading"
          label="Heading"
        />
        <Input
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          id="floatingInputTitle"
          placeholder="Title"
          label="Title"
        />
        <TextArea
          name="Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          id="floatingDescription2"
          boxHeight="5rem"
        />
        <div className="d-flex">
          {existingImage && (
            <img
              src={existingImage}
              alt={existingImage}
              className="img-fluid mb-2 col-1"
            />
          )}
          {
            <Input
              type="file"
              onChange={handleImageChange}
              id="floatingInputImage"
              placeholder="Image"
              label="Image - Please select file less than 5 MB"
              name={existingImage}
              defaultValue={existingImage}
            />
          }
        </div>

        <div className="text-center">
          <Button name="Update" type="submit" className="btn btn-warning" />
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default Update;
