import React, { useState } from "react";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import Input from "../../../common/Input";
import Button from "../../../common/Button";

const Add = () => {
  const [categoryName, setCategoryName] = useState("");

  // error messages
  const created = "Created Successfully";
  const errorMessage = "something Bad Happend";
  const categoryNameError = "categoryName is missing";

  const notifyCreate = (message) => toast.success(message);
  const notifyError = (message) => toast.error(message);

  const handleCategoryNameChange = (e) => {
    setCategoryName(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    switch (true) {
      case !categoryName:
        notifyError(categoryNameError);
        break;

      default:
        try {
          // send a POST request to the server to add the product
          const response = await axios.post("/api/footer/category/add", {
            categoryName,
          });
          notifyCreate(created);
          // handle the response and perform any necessary actions
          console.log(response);
          console.log(response.data);

          // reset the form
          setCategoryName("");
        } catch (error) {
          notifyError(errorMessage);
          console.error(error);
        }
        break;
    }
  };

  return (
    <div>
      <h3 className="text-center">Add Footer Category</h3>
      <form onSubmit={handleSubmit}>
        <Input
          value={categoryName}
          onChange={handleCategoryNameChange}
          id="categoryName"
          placeholder="Category Name"
          label="Category Name"
        />
        <div className="text-center">
          <Button name="Submit" type="submit" className="btn btn-primary" />
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default Add;
