import React from "react";

const Map = () => {
  return (
    <div className="container-fluid">
      <div className="col-md-12 col-md-12 p-1 mt-5 pt-5">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13622.163932675337!2d74.3526122!3d31.3992179!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3919052f9e0ba3b5%3A0x89658754294c2208!2sVoltonic%20Solution%20Pvt.%20Ltd.!5e0!3m2!1sen!2s!4v1702463525317!5m2!1sen!2s"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
          className="col-12"
          height={465}
        ></iframe>
      </div>
    </div>
  );
};

export default Map;
