import React, { useState } from "react";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import Input from "../../../common/Input";
import Button from "../../../common/Button";
import TextArea from "../../../common/TextArea";
import IconsList from "../../../common/IconsList";
import DataList from "../../../common/DataList";

const Add = () => {
  const [heading, setHeading] = useState("");
  const [description, setDescription] = useState("");

  // error messages
  const created = "Created Successfully";
  const errorMessage = "something Bad Happend";
  const descriptionError = "Description is missing";
  const headingError = "Heading is missing";

  const notifyCreate = (message) => toast.success(message);
  const notifyError = (message) => toast.error(message);

  const handleHeadingChange = (e) => {
    setHeading(e.target.value);
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    switch (true) {
      case !heading:
        notifyError(headingError);
        break;

      case !description:
        notifyError(descriptionError);
        break;

      default:
        try {
          // send a POST request to the server to add the product
          const response = await axios.post("/api/service/header/add", {
            heading,
            description,
          });
          notifyCreate(created);
          // handle the response and perform any necessary actions
          console.log(response);
          console.log(response.data);

          // reset the form
          setHeading("");
          setDescription("");
        } catch (error) {
          notifyError(errorMessage);
          console.error(error);
        }
        break;
    }
  };

  return (
    <div>
      <h3 className="text-center">Add Service Header</h3>
      <form onSubmit={handleSubmit}>
        <Input
          value={heading}
          onChange={handleHeadingChange}
          id="floatingInputHeading"
          placeholder="Heading"
          label="Heading"
        />
        <TextArea
          name="Description"
          value={description}
          onChange={handleDescriptionChange}
          id="floatingDescription2"
          boxHeight="5rem"
        />
        <div className="text-center">
          <Button name="Submit" type="submit" className="btn btn-primary" />
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default Add;
