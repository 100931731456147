import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";

const Navbar = () => {
  const [touchdata, setTouchData] = useState([]);
  const [logo, setLogo] = useState([]);
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [projectsBottom, setProjectsBottom] = useState([]);
  const currentYear = new Date().getFullYear();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const loadItems = async () => {
      const { data } = await axios.get("/api/get-in-touch/show-all");
      setTouchData(data);
      console.log(data);
    };
    loadItems();
  }, []);

  useEffect(() => {
    const loadItems = async () => {
      const { data } = await axios.get("/api/logo/show-all");
      setLogo(data);
      console.log(data);
    };
    loadItems();
  }, []);

  useEffect(() => {
    const loadItems = async () => {
      const { data } = await axios.get("/api/project/bottom/show-all");
      setProjectsBottom(data);
      console.log(data);
    };
    loadItems();
  }, []);

  return (
    <>
      {/* /.header-top home-one */}
      <header className="header header-home-two">
        <nav className="navbar navbar-default header-navigation">
          <div className="logo pull-left">
            {logo.length > 0 && (
              <Link to="/">
                <img
                  className="img-fluid ms-5"
                  src={`/uploads/${logo[0].image}`}
                  alt="Voltonic Systems logo"
                  style={{ width: "150px" }}
                />
              </Link>
            )}
          </div>
          <div className="container clearfix">
            {/* Brand and toggle get grouped for better mobile display */}
            <div className="navbar-header">
              <button
                className="side-nav-toggler side-nav-opener"
                onClick={toggleMenu}
              >
                <i className="fa fa-bars" />
              </button>
            </div>
            {/* Collect the nav links, forms, and other content for toggling */}
            <div
              className="collapse navbar-collapse main-navigation mainmenu "
              id="main-nav-bar"
            >
              <ul className="nav navbar-nav navigation-box">
                <li className={location.pathname === "/" ? "current" : ""}>
                  <NavLink className="text-decoration-none" exact to="/">
                    Home
                  </NavLink>
                </li>
                <li
                  className={location.pathname === "/about-us" ? "current" : ""}
                >
                  <Link className="text-decoration-none" to="/about-us">
                    About Us
                  </Link>{" "}
                </li>
                <li
                  className={location.pathname === "/services" ? "current" : ""}
                >
                  <Link className="text-decoration-none" to="/services">
                    Services
                  </Link>
                </li>
                <li
                  className={location.pathname === "/projects" ? "current" : ""}
                >
                  <Link className="text-decoration-none" to="/projects">
                    Projects
                  </Link>
                </li>
              </ul>
            </div>
            {/* /.navbar-collapse */}
            <div className="right-side-box">
              <Link
                to="/contact-us"
                className="rqa-btn hvr-sweep-to-right text-decoration-none"
              >
                <Link
                  className="text-decoration-none text-white"
                  to="/contact-us"
                >
                  <span className="inner">
                    Contact Us&nbsp;
                    <i className="fa fa-arrow-right" />
                  </span>
                </Link>
              </Link>
            </div>
            {/* /.right-side-box */}
          </div>
          {/* /.container */}
        </nav>
      </header>
      {/* /.header */}

      <section
        className={`hidden-sidebar ${isMenuOpen ? "open" : ""} side-navigation`}
      >
        <bubtton
          className="close-button btn btn-link text-decoration-none side-navigation-close-btn fa fa-times"
          onClick={toggleMenu}
        ></bubtton>
        {/* /.close-button */}
        <div className="sidebar-content">
          <div className="top-content">
            {logo.length > 0 && (
              <Link to="/">
                <img
                  className="img-fluid"
                  src={`/uploads/${logo[0].image}`}
                  alt="Voltonic Systems logo"
                  style={{ width: "200px" }}
                  onClick={toggleMenu}
                />
              </Link>
            )}
          </div>
          {/* /.top-content */}
          <nav className="nav-menu middle-content">
            <ul class="navigation-box">
              <li class="current">
                <Link
                  to="/"
                  onClick={toggleMenu}
                  className="text-decoration-none"
                >
                  Home <span class="subnav-toggler"></span>
                </Link>
              </li>
              <li>
                <Link
                  onClick={toggleMenu}
                  className="text-decoration-none"
                  to="/about-us"
                >
                  About Us
                </Link>
              </li>
              <li>
                <Link
                  onClick={toggleMenu}
                  className="text-decoration-none"
                  to="/services"
                >
                  Services <span class="subnav-toggler"></span>
                </Link>
              </li>
              <li>
                <Link
                  onClick={toggleMenu}
                  className="text-decoration-none"
                  to="/projects"
                >
                  Projects <span class="subnav-toggler"></span>
                </Link>
              </li>
              <li>
                <Link
                  onClick={toggleMenu}
                  className="text-decoration-none"
                  to="/contact-us"
                >
                  Contact Us
                </Link>{" "}
              </li>
              <li>
                <bubtton
                  className="close-button btn btn-link text-white text-decoration-none side-navigation-close-btn fa fa-times"
                  onClick={toggleMenu}
                >
                  <h1 className="fw-bold">X</h1>
                </bubtton>
              </li>
            </ul>
          </nav>
          {/* /.nav-menu */}
          <div class="bottom-content">
            {projectsBottom.length > 0 ? (
              <div className="social mb-0">
                {projectsBottom[0].facebook !== "" ? (
                  <Link
                    target="_blank"
                    href={projectsBottom[0].facebook}
                    className="fab fa-facebook"
                    style={{ textDecoration: "none" }}
                  />
                ) : (
                  ""
                )}
                {projectsBottom[0].twitter !== "" ? (
                  <Link
                    target="_blank"
                    href={projectsBottom[0].twitter}
                    className="fab fa-twitter-square"
                    style={{ textDecoration: "none" }}
                  />
                ) : (
                  ""
                )}
                {projectsBottom[0].instagram !== "" ? (
                  <Link
                    target="_blank"
                    href={projectsBottom[0].instagram}
                    className="fab fa-instagram"
                    style={{ textDecoration: "none" }}
                  />
                ) : (
                  ""
                )}
                {projectsBottom[0].linkedin !== "" ? (
                  <Link
                    target="_blank"
                    href={projectsBottom[0].linkedin}
                    className="fab fa-linkedin"
                    style={{ textDecoration: "none" }}
                  />
                ) : (
                  ""
                )}
              </div>
            ) : (
              ""
            )}
            <p class="copy-text">
              &copy; {currentYear} Voltonic Solution <br /> Developed{" "}
              <i class="fa fa-heart"></i> by Voltonic Solution Tech Team
            </p>
          </div>
          {/* /.bottom-content */}
        </div>
        {/* /.sidebar-content */}
      </section>
      {/* /.hidden-sidebar */}
    </>
  );
};

export default Navbar;
