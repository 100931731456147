import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

const Manage = () => {
  const getRoute = "/api/home/brand/show-all";
  const [data, setData] = useState([]);

  useEffect(() => {
    const loadItems = async () => {
      const { data } = await axios.get(getRoute);
      setData(data);
      console.log(data);
    };
    loadItems();
  }, []);

  const handleDelete = async (itemId) => {
    try {
      await axios.delete(`/api/home/brand/delete/${itemId}`);
      // remove the deleted item from the data array
      setData((prevData) => prevData.filter((item) => item._id !== itemId));
      console.log("item deleted successfully");
    } catch (error) {
      console.error("failed to delete item", error);
    }
  };

  return (
    <div className="container">
      <h3 className="text-center">Manage Brands</h3>
      <table className="table table-striped table-hover">
        <thead>
          <tr>
            <th scope="col">Image</th>
            <th scope="col">Operations</th>
          </tr>
        </thead>
        <tbody>
          {data.map((d) => (
            <tr key={d._id}>
              <td className="col-1">
                <span>
                  <img
                    src={`/uploads/${d.image}`}
                    alt="image"
                    className="col-6 bg-primary"
                    style={{ borderRadius: "50%" }}
                  />
                </span>
              </td>
              <td className="col-3">
                <button
                  className="btn btn-danger"
                  onClick={() => handleDelete(d._id)}
                >
                  Delete
                </button>
                <span className="me-2 ms-2">|</span>
                <Link
                  to={`/admin/home/brand/update/${d._id}`}
                  className="btn btn-warning"
                >
                  Edit
                </Link>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Manage;
