import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import GetInTouch from "./Common/GetInTouch";
import Slider from "react-slick";
import Projects from "./Components/Projects";

const ProjectsPage = () => {
  const [bannerImage, setBannerImage] = useState([]);
  const [projects, setProjects] = useState([]);
  const [projectsBottom, setProjectsBottom] = useState([]);
  const [projectsheadingData, setprojectsHeadingData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("All Projects");
  const sliderRef = useRef();

  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 5, // Number of categories to show at a time
    slidesToScroll: 1,
    centerMode: false,
    variableWidth: true,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024, // Adjust this breakpoint as needed
        settings: {
          slidesToShow: 5, // Number of categories to show on mobile screens
        },
      },
      {
        breakpoint: 768, // Adjust this breakpoint as needed
        settings: {
          slidesToShow: 4, // Number of categories to show on mobile screens
        },
      },
      {
        breakpoint: 425, // Adjust this breakpoint as needed
        settings: {
          slidesToShow: 1, // Number of categories to show on mobile screens
        },
      },
      {
        breakpoint: 375, // Adjust this breakpoint as needed
        settings: {
          slidesToShow: 1, // Number of categories to show on mobile screens
        },
      },
      {
        breakpoint: 320, // Adjust this breakpoint as needed
        settings: {
          slidesToShow: 1, // Number of categories to show on mobile screens
        },
      },
    ],
  };

  const groupProjectsByCategory = (projects) => {
    return projects.reduce((grouped, project) => {
      if (!grouped[project.categoryName]) {
        grouped[project.categoryName] = [];
      }
      grouped[project.categoryName].push(project);
      return grouped;
    }, {});
  };

  const groupedProjects = groupProjectsByCategory(projects);

  const filterProjects = (category) => {
    setSelectedCategory(category);
  };

  const filteredProjects =
    selectedCategory === "All Projects"
      ? projects
      : projects.filter((project) => project.categoryName === selectedCategory);

  const handleNextClick = () => {
    sliderRef.current.slickNext();
  };

  const handlePrevClick = () => {
    sliderRef.current.slickPrev();
  };

  useEffect(() => {
    const loadItems = async () => {
      const { data } = await axios.get("/api/project/banner/show-all");
      setBannerImage(data);
      console.log(data);
    };
    loadItems();
  }, []);

  useEffect(() => {
    const loadItems = async () => {
      const { data } = await axios.get("/api/home-projects/header/show-all");
      setprojectsHeadingData(data);
      console.log(data);
    };
    loadItems();
  }, []);

  useEffect(() => {
    const loadItems = async () => {
      const { data } = await axios.get("/api/project/show-all");
      setProjects(data);
      console.log(data);
    };
    loadItems();
  }, []);

  useEffect(() => {
    const loadItems = async () => {
      const { data } = await axios.get("/api/project/bottom/show-all");
      setProjectsBottom(data);
      console.log(data);
    };
    loadItems();
  }, []);

  const bannerStyle = {
    backgroundImage: `url(/uploads/${
      bannerImage.length > 0 ? bannerImage[0].image : ""
    })`,
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  };

  const bgImageStyle = {
    backgroundImage: `url(/uploads/${
      projectsBottom.length > 0 ? projectsBottom[0].image : ""
    })`,
    backgroundPosition: "center center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    width: "100%",
    height: "auto",
  };

  return (
    <>
      <div className="inner-banner" style={bannerStyle}>
        <div className="container">
          <h3>Project Page</h3>
          <ul className="breadcumb">
            <li>
              <Link className="text-decoration-none" to="/">
                Home
              </Link>
            </li>
            {/*
             */}
            <li>
              <span className="sep">
                <i className="fa fa-angle-right" />
              </span>
            </li>
            {/*
             */}
            <li>
              <span>Project Page</span>
            </li>
          </ul>
          {/* /.breadcumb */}
        </div>
        {/* /.container */}
      </div>
      {/* /.inner-banner */}
      <Projects />

      <GetInTouch />
      {/* /.contact-info-style-one */}
    </>
  );
};

export default ProjectsPage;
