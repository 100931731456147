import React, { useState } from "react";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import Input from "../../../common/Input";
import Button from "../../../common/Button";

const Add = () => {
  const [countryName, setCountryName] = useState("");

  // error messages
  const created = "Created Successfully";
  const errorMessage = "something Bad Happend";
  const countryNameError = "countryName is missing";

  const notifyCreate = (message) => toast.success(message);
  const notifyError = (message) => toast.error(message);

  const handlecountryNameChange = (e) => {
    setCountryName(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    switch (true) {
      case !countryName:
        notifyError(countryNameError);
        break;

      default:
        try {
          // send a POST request to the server to add the product
          const response = await axios.post(
            "/api/contact-us/footer/country/add",
            {
              countryName,
            }
          );
          notifyCreate(created);
          // handle the response and perform any necessary actions
          console.log(response);
          console.log(response.data);

          // reset the form
          setCountryName("");
        } catch (error) {
          notifyError(errorMessage);
          console.error(error);
        }
        break;
    }
  };

  return (
    <div>
      <h3 className="text-center">Add Footer Country Name</h3>
      <form onSubmit={handleSubmit}>
        <Input
          value={countryName}
          onChange={handlecountryNameChange}
          id="countryName"
          placeholder="Country Name"
          label="Country Name"
        />
        <div className="text-center">
          <Button name="Submit" type="submit" className="btn btn-primary" />
        </div>
      </form>
      <ToastContainer />
    </div>
  );
};

export default Add;
