import React from "react";

const CustomAccordian = ({ id, buttonTarget, accordianName, children }) => {
  return (
    <div className="accordion-item">
      <h2 className="accordion-header" id={id}>
        <button
          className="accordion-button collapsed"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={`#${buttonTarget}`}
          aria-expanded="false"
          aria-controls={buttonTarget}
        >
          {accordianName}
        </button>
      </h2>
      <div
        id={buttonTarget}
        className="accordion-collapse collapse"
        aria-labelledby={id}
        data-bs-parent={`#${id}`}
      >
        <div className="list-group">{children}</div>
      </div>
    </div>
  );
};

export default CustomAccordian;
