import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import CustomDropDown from "../common/CustomDropDown";
import CustomAccordian from "../common/CustomAccordian";

const Dashboard = ({ Component }) => {
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [cookies, removeCookie] = useCookies([]);

  useEffect(() => {
    const verifyCookie = async () => {
      if (!cookies.token) {
        navigate("/login");
      }

      try {
        const { data } = await axios.post(
          "/api/user/verify",
          {},
          { withCredentials: true }
        );
        const { status, user } = data;

        setUsername(user);

        if (status) {
          console.log(`Hello ${user}`);
        } else {
          removeCookie("token");
          navigate("/login");
        }
      } catch (error) {
        console.error("Error while verifying cookie:", error);
        removeCookie("token");
        navigate("/login");
      }
    };
    verifyCookie();
  }, [cookies, navigate, removeCookie]);

  const logout = () => {
    removeCookie("token");
    navigate("/");
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-2">
          {/* sidebar */}
          <h1 className="text-center">Admin</h1>
          <h4 className="text-center text-primary">{username}</h4>
          {username ? (
            <>
              <button onClick={logout} className="btn btn-danger">
                Logout
              </button>
              <div
                className="accordion accordion-flush"
                id="accordionFlushExample"
              >
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingThree">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseSeven"
                      aria-expanded="false"
                      aria-controls="flush-collapseSeven"
                    >
                      Navbar Logo
                    </button>
                  </h2>
                  <div
                    id="flush-collapseSeven"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingSeven"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      <Link className="dropdown-item " to="/admin/logo/add">
                        Add
                      </Link>
                      <Link className="dropdown-item" to="/admin/logo/manage">
                        Manage
                      </Link>
                    </div>
                  </div>
                </div>
                <CustomAccordian
                  accordianName="Home Page"
                  id="one"
                  buttonTarget="cone"
                >
                  <>
                    <CustomDropDown
                      buttonName="Slider"
                      addRecordPath="/admin/home/slider/add"
                      manageRecordPath="/admin/home/slider/manage"
                    />
                    {/* <CustomDropDown
                      buttonName="Header"
                      addRecordPath="/admin/home/header/add"
                      manageRecordPath="/admin/home/header/manage"
                    /> */}
                    {/* <CustomDropDown
                      buttonName="Header Points"
                      addRecordPath="/admin/home/header-point/add"
                      manageRecordPath="/admin/home/header-point/manage"
                    /> */}
                    {/* <CustomDropDown
                      buttonName="Features / Services"
                      addRecordPath="/admin/home/service/add"
                      manageRecordPath="/admin/home/service/manage"
                    /> */}
                    <CustomDropDown
                      buttonName="Projects Heading"
                      addRecordPath="/admin/home-projects/header/add"
                      manageRecordPath="/admin/home-projects/header/manage"
                    />
                    <CustomDropDown
                      buttonName="Brands"
                      addRecordPath="/admin/home/brand/add"
                      manageRecordPath="/admin/home/brand/manage"
                    />
                  </>
                </CustomAccordian>
                <CustomAccordian
                  accordianName="About Us Page"
                  id="two"
                  buttonTarget="ctwo"
                >
                  <>
                    <CustomDropDown
                      buttonName="Banner"
                      addRecordPath="/admin/about/banner/add"
                      manageRecordPath="/admin/about/banner/manage"
                    />
                    {/* <CustomDropDown
                      buttonName="Header"
                      addRecordPath="/admin/about/header/add"
                      manageRecordPath="/admin/about/header/manage"
                    /> */}
                    <CustomDropDown
                      buttonName="Features"
                      addRecordPath="/admin/about/features/add"
                      manageRecordPath="/admin/about/features/manage"
                    />
                    {/* <CustomDropDown
                      buttonName="History Header"
                      addRecordPath="/admin/about/history-header/add"
                      manageRecordPath="/admin/about/history-header/manage"
                    /> */}
                    {/* <CustomDropDown
                      buttonName="History Work"
                      addRecordPath="/admin/about/history-work/add"
                      manageRecordPath="/admin/about/history-work/manage"
                    /> */}
                  </>
                </CustomAccordian>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingThree">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseThree"
                      aria-expanded="false"
                      aria-controls="flush-collapseThree"
                    >
                      Get In Touch
                    </button>
                  </h2>
                  <div
                    id="flush-collapseThree"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingThree"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      <Link
                        className="dropdown-item "
                        to="/admin/get-in-touch/add"
                      >
                        Add
                      </Link>
                      <Link
                        className="dropdown-item"
                        to="/admin/get-in-touch/manage"
                      >
                        Manage
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingNine">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseNine"
                      aria-expanded="false"
                      aria-controls="flush-collapseNine"
                    >
                      Subscribe
                    </button>
                  </h2>
                  <div
                    id="flush-collapseNine"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingNine"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      <Link
                        className="dropdown-item "
                        to="/admin/subscribe/add"
                      >
                        Add
                      </Link>
                      <Link
                        className="dropdown-item"
                        to="/admin/subscribe/manage"
                      >
                        Manage
                      </Link>
                    </div>
                  </div>
                </div>
                <CustomAccordian
                  accordianName="Projects Page"
                  id="three"
                  buttonTarget="cthree"
                >
                  <>
                    <CustomDropDown
                      buttonName="Banner"
                      addRecordPath="/admin/project/banner/add"
                      manageRecordPath="/admin/project/banner/manage"
                    />
                    <CustomDropDown
                      buttonName="Projects"
                      addRecordPath="/admin/project/add"
                      manageRecordPath="/admin/project/manage"
                    />
                    {/* <CustomDropDown
                      buttonName="Bottom Area"
                      addRecordPath="/admin/project/bottom/add"
                      manageRecordPath="/admin/project/bottom/manage"
                    /> */}
                  </>
                </CustomAccordian>
                <CustomAccordian
                  accordianName="Services Page"
                  id="four"
                  buttonTarget="cfour"
                >
                  <>
                    <CustomDropDown
                      buttonName="Banner"
                      addRecordPath="/admin/service/banner/add"
                      manageRecordPath="/admin/service/banner/manage"
                    />
                    <CustomDropDown
                      buttonName="Service Header"
                      addRecordPath="/admin/service/header/add"
                      manageRecordPath="/admin/service/header/manage"
                    />
                    <CustomDropDown
                      buttonName="Services"
                      addRecordPath="/admin/service/add"
                      manageRecordPath="/admin/service/manage"
                    />
                  </>
                </CustomAccordian>
                <CustomAccordian
                  accordianName="Contact Us"
                  id="five"
                  buttonTarget="cfive"
                >
                  <>
                    <CustomDropDown
                      buttonName="Banner"
                      addRecordPath="/admin/contact-us/banner/add"
                      manageRecordPath="/admin/contact-us/banner/manage"
                    />
                    {/* <CustomDropDown
                      buttonName="Header"
                      addRecordPath="/admin/contact-us/header/add"
                      manageRecordPath="/admin/contact-us/header/manage"
                    /> */}
                    <CustomDropDown
                      buttonName="Contact Form"
                      addRecordPath="/admin/contact-us/form/add"
                      manageRecordPath="/admin/contact-us/form/manage"
                    />
                    <CustomDropDown
                      buttonName="Footer Menu Company"
                      addRecordPath="/admin/contact-us/footer/company/add"
                      manageRecordPath="/admin/contact-us/footer/company/manage"
                    />
                    <CustomDropDown
                      buttonName="Footer Menu Country"
                      addRecordPath="/admin/contact-us/footer/country/add"
                      manageRecordPath="/admin/contact-us/footer/country/manage"
                    />
                    <CustomDropDown
                      buttonName="Contact Company Content"
                      addRecordPath="/admin/contact-us/footer/content/add"
                      manageRecordPath="/admin/contact-us/footer/content/manage"
                    />
                  </>
                </CustomAccordian>
                <div className="accordion-item">
                  <h2 className="accordion-header" id="flush-headingSix">
                    <button
                      className="accordion-button collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseSix"
                      aria-expanded="false"
                      aria-controls="flush-collapseSix"
                    >
                      Support
                    </button>
                  </h2>
                  <div
                    id="flush-collapseSix"
                    className="accordion-collapse collapse"
                    aria-labelledby="flush-headingSix"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      <Link className="dropdown-item " to="/admin/support/add">
                        Add
                      </Link>
                      <Link
                        className="dropdown-item"
                        to="/admin/support/manage"
                      >
                        Manage
                      </Link>
                    </div>
                  </div>
                </div>
                <CustomAccordian
                  // accordianName="Choose Us"
                  accordianName="Our Goal"
                  id="six"
                  buttonTarget="csix"
                >
                  <>
                    <CustomDropDown
                      buttonName="Header"
                      addRecordPath="/admin/choose-us/header/add"
                      manageRecordPath="/admin/choose-us/header/manage"
                    />
                    <CustomDropDown
                      buttonName="Progress"
                      addRecordPath="/admin/choose-us/progress/add"
                      manageRecordPath="/admin/choose-us/progress/manage"
                    />
                    <CustomDropDown
                      buttonName="Fact"
                      addRecordPath="/admin/choose-us/fact/add"
                      manageRecordPath="/admin/choose-us/fact/manage"
                    />
                  </>
                </CustomAccordian>
                <CustomAccordian
                  accordianName="Footer"
                  id="seven"
                  buttonTarget="cseven"
                >
                  <>
                    <CustomDropDown
                      buttonName="Left Side"
                      addRecordPath="/admin/footer/left-side/add"
                      manageRecordPath="/admin/footer/left-side/manage"
                    />
                    <CustomDropDown
                      buttonName="Category"
                      addRecordPath="/admin/footer/category/add"
                      manageRecordPath="/admin/footer/category/manage"
                    />
                    <CustomDropDown
                      buttonName="Content"
                      addRecordPath="/admin/footer/add"
                      manageRecordPath="/admin/footer/manage"
                    />
                  </>
                </CustomAccordian>
              </div>
            </>
          ) : (
            ""
          )}{" "}
        </div>
        <div className="col-md-10">
          <h1 className="text-center">Dashboard</h1>
          {Component ? <Component /> : <span></span>}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
